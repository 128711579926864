import React, { useContext } from 'react';
import { message, Input, Form, Button, Modal } from 'antd';

import { FirebaseContext, AuthUserContext } from './../../context';

const FinancialInfoForm = ({ onFinish, onFinishFailed, loading }) => (
  <Form
    name="financial_form"
    className={'modal-form'}
    layout={'vertical'}
    // initialValues={{ remember: false }}
    onFinish={(values) => onFinish(values)}
    onFinishFailed={onFinishFailed}
  >
    <Form.Item
      label="Título"
      name="title"
      rules={[{ required: true, message: 'Agregar un titulo' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Url a mostrar (No se puede cambiar despues)"
      name="urlShow"
      rules={[{ required: true, message: 'Agregar una url (UNICA)' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Resumen"
      name="summary"
      rules={[{ required: true, message: 'Agregar un resumen' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Enlace Foto a mostrar junto al resumen"
      name="photoUrl"
      rules={[{ required: true, message: 'Agregar una foto a la noticia' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Contenido (soporta markdown)"
      name="content"
      rules={[{ required: true, message: 'Agregar el contenido' }]}
    >
      <Input.TextArea />
    </Form.Item>

    <Form.Item className={'button-holder'}>
      <Button type="primary" htmlType="submit" loading={loading}>
        Crear
      </Button>
    </Form.Item>
  </Form>
);

const LinkForm = ({ onFinish, onFinishFailed, loading }) => (
  <Form
    name="link_form"
    className={'modal-form'}
    layout={'vertical'}
    // initialValues={{ remember: false }}
    onFinish={(values) => onFinish(values)}
    onFinishFailed={onFinishFailed}
  >
    <Form.Item
      label="Título"
      name="title"
      rules={[{ required: true, message: 'Agregar un titulo' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Resumen"
      name="summary"
      rules={[{ required: true, message: 'Agregar un resumen' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Enlace Foto a mostrar junto al resumen"
      name="photoUrl"
      rules={[{ required: true, message: 'Agregar una foto a la noticia' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Enlace externo"
      name="link"
      rules={[{ required: true, message: 'Agregar un link a la noticia' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item className={'button-holder'}>
      <Button type="primary" htmlType="submit" loading={loading}>
        Crear noticia
      </Button>
    </Form.Item>
  </Form>
);

const ContentForm = ({ onFinish, onFinishFailed, loading }) => (
  <Form
    className={'modal-form'}
    layout={'vertical'}
    name="link_form"
    // initialValues={{ remember: false }}
    onFinish={(values) => onFinish(values)}
    onFinishFailed={onFinishFailed}
  >
    <Form.Item
      label="Título"
      name="title"
      rules={[{ required: true, message: 'Agregar un titulo' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Enlace Foto a mostrar junto al resumen"
      name="photoUrl"
      rules={[{ required: true, message: 'Agregar una foto a la noticia' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Resumen"
      name="summary"
      rules={[{ required: true, message: 'Agregar un resumen' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Contenido"
      name="article_content"
      rules={[{ required: true, message: 'Agregar contenido a la noticia' }]}
    >
      <Input.TextArea />
    </Form.Item>

    <Form.Item className={'button-holder'}>
      <Button type="primary" htmlType="submit" loading={loading}>
        Crear noticia
      </Button>
    </Form.Item>
  </Form>
);

const NewArticleModal = ({ openModal, setModal, loading, setLoading }) => {
  //   const [loading, setLoading] = useState(false);
  const authUser = useContext(AuthUserContext);
  const firebase = useContext(FirebaseContext);

  const onFinish = (values) => {
    setLoading(true);
    firebase.articleLogic
      .newArticle(openModal, values, authUser.uid)
      .then(() => {
        message.success('Articulo creado correctamente');
        setLoading(false);
        setModal(null);
      })
      .catch((error) => {
        message.error(error.message);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    message.error('Error');
  };

  const renderFormModal = (modalType) => {
    switch (modalType) {
      case 'link':
        return (
          <div id="article-modal-body">
            <h3>Nuevo artículo con enlace </h3>
            <LinkForm
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              loading={loading}
            />
          </div>
        );
      case 'full':
        return (
          <div id="article-modal-body">
            <h3>Nuevo artículo con contenido</h3>
            <ContentForm
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              loading={loading}
            />
          </div>
        );

      case 'financialEducation':
        return (
          <div id="article-modal-body">
            <h3>Nuevo artículo de educación financiera</h3>
            <FinancialInfoForm
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              loading={loading}
            />
          </div>
        );

      default:
        return;
    }
  };

  return (
    <Modal
      onCancel={() => {
        setModal(null);
      }}
      confirmLoading={loading}
      footer={<div id="assign-modal-footer"></div>}
      visible={openModal}
    >
      {renderFormModal(openModal)}
    </Modal>
  );
};

export default NewArticleModal;
