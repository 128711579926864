// Bank Office API //
class bankOfficeLogic {
  // Definir constructor de la clase
  constructor(db, fieldValue, functions) {
    this.db = db;
    this.fieldValue = fieldValue;
    this.functions = functions;
  }

  // Funciones de la clase para Otras Colecciones
  /**
   *
   * @param {*} id
   * Devuelve un documento de la colección users.
   */
  getUserById = (id) => this.db.doc(`users/${id}`);

  /**
   * Devuelve todos los usuarios de la colección users.
   */
  getUsers = () => this.db.collection('users');

  /**
   *
   * @param {*} emails
   * Devuelve un conjunto de documentos en función de los parámetros de entrada.
   */
  getUsersByParams = (bankId, bankOfficeId) =>
    this.db
      .collection('users')
      .where('bankId', '==', bankId)
      .where('bankOfficeId', '==', bankOfficeId);

  /**
   *
   * @param {*} id
   * Devuelve un documento de la colección banks.
   */
  getBankById = (id) => this.db.doc(`banks/${id}`);

  /**
   *
   * @param {*} email
   * Verifica si un usuario ya se encuentra registrado en la colección users.
   */
  checkEmailUser = async (email) => {
    const query = await this.db
      .collection('users')
      .where('email', '==', email)
      .get();
    if (!query.empty) {
      const roles = query.docs[0].get('roles');
      if (roles.ADMIN || roles.BANKEXECUTIVE || roles.BANKADMIN || roles.NORMAL) {
        return Promise.reject(new Error('El usuario ya tiene un rol asignado'));
      }
    }
    return Promise.resolve();
  };

  // Funciones de la Clase para la Colección bankOffices
  /**
   *
   * @param {*} id
   * Devuelve un documento de la colección bankOffices.
   */
  getBankOffice = (id) => this.db.doc(`bankOffices/${id}`);

  /**
   * Devuelve todos los documentos de la colección bankOffices.
   */
  getBankOffices = () => this.db.collection('bankOffices');

  /**
   *
   * @param {*} values
   * Crea un nuevo documento para la colección bankOffices.
   */
  createBankOffice = async (values) => {
    // Obtener datos de entrada
    const { bankName, bankId, bankOfficeAppTypes, bankOfficeName } = values;

    // Verificar que la sucursal no se encuentre almacenada en la BD
    // y almacenar su información.
    const querySnapshot = await this.getBankOffices()
      .where('bankOfficeName', '==', bankOfficeName)
      .where('bankName', '==', bankName)
      .get();
    if (querySnapshot.empty) {
      try {
        // Crear sucursal
        const bankOffice = await this.getBankOffices().add({
          bankName,
          bankId,
          bankOfficeName,
          bankOfficeAppTypes,
          bankOfficeAdmins: [],
          bankOfficeExecutives: [],
          createdAt: this.fieldValue.serverTimestamp(),
        });

        // Agregar ID de la sucursal al banco
        await this.getBankById(bankId).update({
          bankOffices: this.fieldValue.arrayUnion(bankOffice.id),
        });

        // Devolver promesa resuelta
        return Promise.resolve();
      } catch {
        return Promise.reject(
          new Error(
            'Hubo un error al intentar crear la información de la sucursal en la base de datos'
          )
        );
      }
    } else {
      return Promise.reject(
        new Error('Ya existe una sucursal con ese nombre para el banco seleccionado')
      );
    }
  };

  /**
   *
   * @param {*} values
   * Edita la información de una sucursal.
   */
  updateBankOffice = async (values) => {
    // Intentar actualizar la información en la BD
    try {
      // Obtener datos de entrada
      const {
        bankOfficeAppTypes,
        bankOfficeOldAppTypes,
        bankName,
        bankId,
        bankOfficeId,
        bankOfficeName,
      } = values;

      // Definir arreglo de promesas
      let promises = [];

      // Obtener información de la sucursal
      const bankOffice = await this.getBankOffice(bankOfficeId).get();

      // Agregar primera promesa según sea el caso
      if (bankOffice.data().bankOfficeName === bankOfficeName) {
        promises.push(
          this.getBankOffice(bankOfficeId).update({
            bankOfficeAppTypes,
          })
        );
      } else {
        // Verificar si el nombre no se encuentra registrado
        const querySnapshot = await this.getBankOffices()
          .where('bankOfficeName', '==', bankOfficeName)
          .where('bankName', '==', bankName)
          .get();
        if (querySnapshot.empty) {
          promises.push(
            this.getBankOffice(bankOfficeId).update({
              bankOfficeName,
              bankOfficeAppTypes,
            })
          );
        } else {
          return Promise.reject(
            new Error(
              'Ya existe una sucursal con ese nombre para el banco seleccionado'
            )
          );
        }
      }

      // Obtener información de todos los usuarios (BANKADMIN y BANKEXECUTIVE)
      const usersData = await this.getUsersByParams(bankId, bankOfficeId).get();

      // Actualizar información de los administradores y ejecutivos según sea el caso
      if (
        bankOfficeAppTypes.length < bankOfficeOldAppTypes.length ||
        bankOfficeAppTypes.join('-') !== bankOfficeOldAppTypes.join('-')
      ) {
        // Obtener elementos no comunes de los arreglos
        const removedItemsArray = bankOfficeOldAppTypes.filter(
          (item) => !bankOfficeAppTypes.includes(item)
        );

        // Agregar resto de las promesas según sea el caso
        usersData.docs.forEach((doc) => {
          // Obtener los tipos de solicitudes asociadas al usuario
          let userAppTypes = doc.get('bankOfficeAppTypes');

          // Eliminar tipos innecesario del arreglo anterior
          removedItemsArray.forEach((type) => {
            if (userAppTypes.includes(type)) {
              userAppTypes = userAppTypes.filter((item) => item !== type);
            }
          });

          // Aplicar actualizaciones según sea el caso de la variable userAppType
          // y del tipo de usuario que se esté manejando
          if (userAppTypes.length === 0) {
            if (doc.get('roles').BANKADMIN === 'BANKADMIN') {
              promises.push(
                this.getUserById(doc.id).update({
                  bankOfficeAppTypes,
                  bankOfficeName,
                })
              );
            } else if (doc.get('roles').BANKEXECUTIVE === 'BANKEXECUTIVE') {
              // Colocar ejecutivo como libre
              promises.push(
                this.getUserById(doc.id).update({
                  bankOfficeName: '',
                  bankOfficeId: '',
                  bankOfficeBoss: '',
                  bankOfficeAppTypes: [],
                })
              );

              // Sacar de la sucursal
              promises.push(
                this.getBankOffice(bankOfficeId).update({
                  bankOfficeExecutives: this.fieldValue.arrayRemove(
                    doc.get('email')
                  ),
                })
              );
            }
          } else {
            promises.push(
              this.getUserById(doc.id).update({
                bankOfficeAppTypes: userAppTypes,
                bankOfficeName,
              })
            );
          }
        });
      } else {
        usersData.docs.forEach((doc) => {
          promises.push(
            this.getUserById(doc.id).update({
              bankOfficeName,
            })
          );
        });
      }

      // Devolver resultado de las promesas
      return Promise.all(promises);
    } catch (error) {
      console.log(error);
      return Promise.reject(
        new Error(
          'Hubo un error al intentar actualizar la información de la sucursal en la base de datos'
        )
      );
    }
  };

  /**
   *
   * @param {*} values
   * Elimina la información de una sucursal.
   */
  deleteBankOffice = async (values) => {
    try {
      // Obtener datos de entrada
      const { bankId, bankOfficeId, bankOfficeUsers } = values;

      // Definir arreglo de promesas
      let promises = [
        this.getBankOffice(bankOfficeId).delete(),
        this.getBankById(bankId).update({
          bankOffices: this.fieldValue.arrayRemove(bankOfficeId),
        }),
      ];

      // Obtener información de todos los usuarios (BANKADMIN y BANKEXECUTIVE)
      const usersData = await this.getUsersByParams(bankId, bankOfficeId).get();

      // Agregar resto de las promesas según sea el caso
      usersData.docs.forEach((doc) => {
        // Validar que el correo del documento esté en el arreglo bankOfficeUsers
        if (bankOfficeUsers.includes(doc.get('email'))) {
          // Definir nuevas promesas según el rol del usuario
          if (doc.get('roles').BANKADMIN === 'BANKADMIN') {
            // Eliminar correo del administrador de la colección banks
            promises.push(
              this.getBankById(bankId).update({
                bankAdmins: this.fieldValue.arrayRemove(doc.get('email')),
              })
            );

            // Borrar administrador
            promises.push(this.getUserById(doc.id).delete());
          } else if (doc.get('roles').BANKEXECUTIVE === 'BANKEXECUTIVE') {
            promises.push(
              this.getUserById(doc.id).update({
                bankOfficeName: '',
                bankOfficeId: '',
                bankOfficeBoss: '',
                bankOfficeAppTypes: [],
              })
            );
          }
        }
      });

      // Devolver resultado de las promesas
      return Promise.all(promises);
    } catch (error) {
      console.log(error);
      return Promise.reject(
        new Error(
          'Hubo un error al intentar eliminar la información de la sucursal en la base de datos'
        )
      );
    }
  };

  /**
   *
   * Devuelve todos los documentos de la colección users con el id de la sucursal dado y
   * cuyo rol es BANKADMIN.
   */
  getBankOfficeAdmins = (id) =>
    this.db
      .collection('users')
      .where('bankOfficeId', '==', id)
      .where('roles.BANKADMIN', '==', 'BANKADMIN');

  /**
   *
   * @param {*} values
   * Agrega un administrador a la colección users y actualiza la información de las
   * colecciones banks y bankoffices.
   */
  createBankOfficeAdmin = async (values) => {
    try {
      // Verificar que el email no tenga un rol asignado
      const { uid, email, bankOfficeId } = values;
      await this.checkEmailUser(email);

      // Crear Usuario
      var createUser = this.functions.httpsCallable('createUser');
      await createUser(values);

      // Obtener documento del nuevo usuario
      const query = await this.getUsers().where('email', '==', email).get();
      if (query.empty) {
        return Promise.reject(
          new Error(
            'Hubo un error al intentar crear la información del administrador en la base de datos'
          )
        );
      }

      // Definir arreglo de promesas
      const promises = [
        this.getBankById(uid).update({
          bankAdmins: this.fieldValue.arrayUnion(email),
        }),
        this.getBankOffice(bankOfficeId).update({
          bankOfficeAdmins: this.fieldValue.arrayUnion(email),
        }),
      ];

      // Devolver resultado de las promesas
      return Promise.all(promises);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  };

  /**
   *
   * @param {*} values
   * Edita los tipos de solicitudes manejadas por un administrador.
   */
  editBankOfficeAdmin = async (values) => {
    try {
      // Obtener valores de entrada
      const {
        adminId,
        bankOfficeId,
        bankOfficeAppTypes,
        bankOfficeOldAppTypes,
        email,
      } = values;

      // Definir arreglo de promesas
      let promises = [
        this.getUserById(adminId).update({
          bankOfficeAppTypes,
        }),
      ];

      // Actualizar información de los ejecutivos según sea el caso
      if (
        bankOfficeAppTypes.length < bankOfficeOldAppTypes.length ||
        bankOfficeAppTypes.join('-') !== bankOfficeOldAppTypes.join('-')
      ) {
        // Obtener elementos no comunes de los arreglos
        const removedItemsArray = bankOfficeOldAppTypes.filter(
          (item) => !bankOfficeAppTypes.includes(item)
        );

        // Obtener información de todos los ejecutivos asociados al email de entrada
        const executivesData = await this.getUsers()
          .where('bankOfficeId', '==', bankOfficeId)
          .where('bankOfficeBoss', '==', email)
          .get();

        // Agregar resto de las promesas según sea el caso
        executivesData.docs.forEach((doc) => {
          // Obtener los tipos de solicitudes asociadas al ejecutivo
          let executiveAppTypes = doc.get('bankOfficeAppTypes');

          // Eliminar tipos innecesario del arreglo anterior
          removedItemsArray.forEach((type) => {
            if (executiveAppTypes.includes(type)) {
              executiveAppTypes = executiveAppTypes.filter((item) => item !== type);
            }
          });

          // Aplicar actualizaciones según sea el caso para el valor de la variable execuitveAppTypes
          if (executiveAppTypes.length === 0) {
            promises.push(
              this.getUserById(doc.id).update({
                bankOfficeName: '',
                bankOfficeId: '',
                bankOfficeBoss: '',
                bankOfficeAppTypes: [],
              })
            );

            // Sacar ejecutivo de la sucursal
            promises.push(
              this.getBankOffice(bankOfficeId).update({
                bankOfficeExecutives: this.fieldValue.arrayRemove(doc.get('email')),
              })
            );
          } else {
            promises.push(
              this.getUserById(doc.id).update({
                bankOfficeAppTypes: executiveAppTypes,
              })
            );
          }
        });
      }

      // Devolver resultado de las promesas
      return Promise.all(promises);
    } catch (error) {
      console.log(error);
      return Promise.reject(
        new Error(
          'Hubo un error al intentar actualizar la información del administrador en la base de datos'
        )
      );
    }
  };

  /**
   *
   * @param {*} values
   * Elimina la información de un administrador de la BD y deja libres a sus ejecutivos asociados.
   */
  deleteBankOfficeAdmin = async (values) => {
    try {
      // Obtener valores de entrada
      const { bankId, bankOfficeId, email } = values;

      // Definir arreglo de promesas
      let promises = [
        this.getBankById(bankId).update({
          bankAdmins: this.fieldValue.arrayRemove(email),
        }),
        this.getBankOffice(bankOfficeId).update({
          bankOfficeAdmins: this.fieldValue.arrayRemove(email),
        }),
      ];

      // Obtener información de todos los usuarios (BANKADMIN y BANKEXECUTIVE)
      const usersData = await this.getUsersByParams(bankId, bankOfficeId).get();

      // Agregar resto de las promesas según sea el caso
      usersData.docs.forEach((doc) => {
        if (doc.get('roles').BANKADMIN === 'BANKADMIN') {
          if (doc.get('email') === email) {
            promises.push(this.getUserById(doc.id).delete());
          }
        } else if (doc.get('roles').BANKEXECUTIVE === 'BANKEXECUTIVE') {
          if (doc.get('bankOfficeBoss') === email) {
            promises.push(
              this.getUserById(doc.id).update({
                bankOfficeAppTypes: [],
                bankOfficeBoss: '',
              })
            );
          }
        }
      });

      // Devolver resultado de las promesas
      return Promise.all(promises);
    } catch (error) {
      console.log(error);
      return Promise.reject(
        new Error(
          'Hubo un error al intentar eliminar la información del administrador en la base de datos'
        )
      );
    }
  };

  /**
   *
   * @param {*} id
   * Devuelve todos los documentos de la colección users relacionados con el id de una
   * sucursal y cuyo rol del user es BANKEXECUTIVE.
   */
  getBankOfficeExecutives = (id) =>
    this.db
      .collection('users')
      .where('bankOfficeId', '==', id)
      .where('roles.BANKEXECUTIVE', '==', 'BANKEXECUTIVE');

  /**
   *
   * @param {*} values
   * Agrega un ejecutivo a la colección users y actualiza la información de las
   * colecciones banks y bankoffices.
   */
  createBankOfficeExecutive = async (values) => {
    try {
      // Verificar que el email no tenga un rol asignado
      const { uid, email, bankOfficeId, adminEmail } = values;
      await this.checkEmailUser(email);

      // Crear Usuario
      var createUser = this.functions.httpsCallable('createUser');
      await createUser(values);

      // Obtener documento del nuevo usuario
      const query = await this.getUsers().where('email', '==', email).get();
      if (query.empty) {
        return Promise.reject(
          new Error(
            'Hubo un error al intentar crear la información del ejecutivo en la base de datos'
          )
        );
      }

      // Definir arreglo de promesas
      const promises = [
        this.getBankById(uid).update({
          bankExecutives: this.fieldValue.arrayUnion(email),
        }),
        this.getBankOffice(bankOfficeId).update({
          bankOfficeExecutives: this.fieldValue.arrayUnion(email),
        }),
        this.getUserById(query.docs[0].id).update({
          bankOfficeBoss: adminEmail,
        }),
      ];

      // Devolver resultado de las promesas
      return Promise.all(promises);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  };

  /**
   *
   * @param {*} values
   * Actualiza la información de un ejecutivo en la colección users otorgándole
   * una sucursal y un supervisor (administrador).
   */
  updateBankOfficeFreeExecutive = (values) => {
    try {
      // Obtener datos de entrada
      const {
        adminEmail,
        bankOfficeId,
        bankOfficeName,
        bankOfficeAppTypes,
        executiveId,
        executiveEmail,
      } = values;

      // Definir conjunto de promesas
      const promises = [
        this.getBankOffice(bankOfficeId).update({
          bankOfficeExecutives: this.fieldValue.arrayUnion(executiveEmail),
        }),
        this.getUserById(executiveId).update({
          bankOfficeName,
          bankOfficeId,
          bankOfficeAppTypes,
          bankOfficeBoss: adminEmail,
        }),
      ];

      // Devolver resultado de las promesas
      return Promise.all(promises);
    } catch (error) {
      return Promise.reject(
        new Error(
          'Hubo un error al intentar actualizar la información del ejecutivo libre en la base de datos'
        )
      );
    }
  };

  /**
   *
   * @param {*} values
   * Actualiza la información de un ejecutivo en la colección users asignándole
   * un nuevo supervisor (administrador de banco).
   */
  updateBankOfficeExecutive = (values) => {
    try {
      // Obtener valores
      const { email, executiveId, bankOfficeAppTypes } = values;

      // Actualizar información
      return this.getUserById(executiveId).update({
        bankOfficeAppTypes,
        bankOfficeBoss: email,
      });
    } catch (error) {
      return Promise.reject(
        new Error(
          'Hubo un error al intentar actualizar la información del ejecutivo en la base de datos'
        )
      );
    }
  };

  /**
   * Elimina la información de una sucursal y del supervisor asociado a un
   * ejecutivo.
   */
  deleteBankOfficeExecutive = (values) => {
    try {
      // Obtener datos de entrada
      const { bankOfficeId, executiveId, email } = values;

      // Definir conjunto de promesas
      const promises = [
        this.getBankOffice(bankOfficeId).update({
          bankOfficeExecutives: this.fieldValue.arrayRemove(email),
        }),
        this.getUserById(executiveId).update({
          bankOfficeName: '',
          bankOfficeId: '',
          bankOfficeBoss: '',
          bankOfficeAppTypes: [],
        }),
      ];

      // Devolver resultado de las promesas
      return Promise.all(promises);
    } catch (error) {
      return Promise.reject(
        new Error(
          'Hubo un error al intentar eliminar la información del ejecutivo en la base de datos'
        )
      );
    }
  };
}

export default bankOfficeLogic;
