// Bank API //
class bankLogic {
  // Definir constructor de la clase
  constructor(db, fieldValue, functions) {
    this.db = db;
    this.fieldValue = fieldValue;
    this.functions = functions;
  }

  // Funciones de la Clase para la Colección banks

  /**
   *
   * @param {*} uid
   * Devuelve un documento proveniente de la colección banks en función del uid de entrada.
   */
  bank = (uid) => this.db.doc(`banks/${uid}`);

  /**
   * Devuelve todos los documentos de la colección Banks.
   */
  banks = () => this.db.collection('banks');

  /**
   *
   * @param {*} content
   * Crea un nuevo elemento en la colección Banks.
   */
  newBank = async (content) => {
    // Obtener valores de entrada
    const { bankName } = content;

    // Validar que el nombre del banco no se encuentre registrado
    const querySnapshot = await this.db
      .collection('banks')
      .where('bankName', '==', bankName)
      .get();
    if (querySnapshot.empty) {
      return this.banks().add({
        bankName,
        bankAdmins: [],
        bankExecutives: [],
        bankOffices: [],
        createdAt: this.fieldValue.serverTimestamp(),
      });
    } else {
      return Promise.reject(new Error('Ya existe un banco con ese nombre'));
    }
  };

  /**
   *
   * @param {*} uid
   * Elimina la información de las colecciones users, bankOffices y banks en función
   * del id de entrada.
   */
  bankDelete = async (id) => {
    try {
      // Obtener información de las sucursales y los usuarios asociados al banco
      const [bankOfficesData, usersData] = await Promise.all([
        this.db.collection('bankOffices').where('bankId', '==', id).get(),
        this.db.collection('users').where('bankId', '==', id).get(),
      ]);

      // Crear arreglo de promesas para hacer la eliminación
      let promises = [this.bank(id).delete()];

      bankOfficesData.docs.forEach((doc) => {
        promises.push(this.db.doc(`bankOffices/${doc.id}`).delete());
      });

      usersData.docs.forEach((doc) => {
        promises.push(this.db.doc(`users/${doc.id}`).delete());
      });

      // Devolver resultado de las promesas
      return Promise.all(promises);
    } catch (error) {
      console.log(error);
      return Promise.reject(
        new Error(
          'Hubo un error al intentar eliminar la información del banco en la base de datos'
        )
      );
    }
  };

  /**
   *
   * @param {*} content
   * Permite editar el nombre de un banco en la BD.
   */
  bankUpdate = async (content) => {
    try {
      // Obtener valores de entrada
      const { bankId, bankName, bankOldName } = content;

      // Editar sólo si el nombre es diferente
      if (bankName !== bankOldName) {
        // Verificar si el nombre no se encuentra registrado
        const querySnapshot = await this.banks()
          .where('bankName', '==', bankName)
          .get();

        if (querySnapshot.empty) {
          // Obtener información de las sucursales y los usuarios asociados al banco
          const [bankOfficesData, usersData] = await Promise.all([
            this.db.collection('bankOffices').where('bankId', '==', bankId).get(),
            this.db.collection('users').where('bankId', '==', bankId).get(),
          ]);

          // Crear arreglo de promesas para hacer la actualización
          let promises = [this.bank(bankId).update({ bankName })];

          bankOfficesData.docs.forEach((doc) => {
            promises.push(this.db.doc(`bankOffices/${doc.id}`).update({ bankName }));
          });

          usersData.docs.forEach((doc) => {
            promises.push(this.db.doc(`users/${doc.id}`).update({ bankName }));
          });

          // Devolver resultado de las promesas
          return Promise.all(promises);
        } else {
          return Promise.reject(
            new Error('Ya existe un banco con ese nombre seleccionado')
          );
        }
      }
      return Promise.resolve();
    } catch (error) {
      console.log(error);
      return Promise.reject(
        new Error(
          'Hubo un error al intentar actualizar la información del banco en la base de datos'
        )
      );
    }
  };
}

export default bankLogic;
