import React from 'react';

const HomePage = () => (
  <div className="home-page">
    <div id="home-head">
      <h1>Flit Admin</h1>
    </div>
  </div>
);

export default HomePage;
