// Importar librerías
import React, { useState, useContext } from 'react';
import { message, Input, Form, Button, Modal, Select, Checkbox } from 'antd';

// Importar context de firebase
import { FirebaseContext } from './../../context';

// Importar constantes
import { appOptionsBankOffice } from '../../constants/appTypes';

// Importar subcomponente Option
const { Option } = Select;

const NewBankOfficeModal = ({ openModal, setModal, banks }) => {
  // Obtener información del context
  const firebase = useContext(FirebaseContext);

  // Definir state
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [loading, setLoading] = useState(false);

  // Crear instancias de useForm
  const [bankOfficeInfoFormInstance] = Form.useForm();

  /**
   *
   * @param {*} values
   * Almacena la información de la nueva sucursal que se está creando en la BD.
   */
  const onFinish = async (values) => {
    // Habilitar carga del spinner
    setLoading(true);

    // Agregar información adicional a los valores de entrada
    values = {
      ...values,
      bankId: selectedBankId,
    };

    // Almacenar en la Base de Datos
    try {
      await firebase.bankOfficeLogic.createBankOffice(values);

      // Mostrar mensaje de éxito
      message.success('Sucursal creada exitosamente');

      // Limpiar formulario
      bankOfficeInfoFormInstance.resetFields();

      // Setear valor para el state Modal
      setModal(false);
    } catch (error) {
      message.error(error.message);
    }

    // Deshabilitar carga del spinner
    setLoading(false);
  };

  /**
   *
   * @param {*} errorInfo
   * Muestra un error en caso de que no se haya podido guardar la información en la BD.
   */
  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  // Renderizar componente
  return (
    <Modal
      onCancel={() => {
        setModal(null);
      }}
      confirmLoading={loading}
      footer={<div id="bank-modal-footer"></div>}
      visible={openModal}
    >
      <div id="bank-modal-body">
        <h3>Agregar Nueva Sucursal</h3>
        <Form
          form={bankOfficeInfoFormInstance}
          className={'modal-form'}
          layout={'vertical'}
          name="link_form"
          onFinish={(values) => onFinish(values)}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Nombre de la Sucursal"
            name="bankOfficeName"
            rules={[{ required: true, message: 'Agregue el nombre de la sucursal' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Nombre del Banco"
            name="bankName"
            rules={[{ required: true, message: 'Seleccione el nombre del banco' }]}
          >
            <Select
              placeholder="Selecciona"
              onSelect={(_, selectedBank) => setSelectedBankId(selectedBank.key)}
            >
              {banks &&
                banks.docs.map((item) => (
                  <Option key={item.id} value={item.get('bankName')}>
                    {item.get('bankName')}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Tipo de Solicitudes"
            name="bankOfficeAppTypes"
            rules={[
              {
                required: true,
                message:
                  'Seleccione al menos un tipo de solicitud que maneje la sucursal',
              },
            ]}
          >
            <Checkbox.Group options={appOptionsBankOffice} />
          </Form.Item>
          <Form.Item className={'button-holder'}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Crear
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default NewBankOfficeModal;
