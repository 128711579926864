// Importar librerías
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

const MonthyCharts = ({ data }) => {
  // Renderizar componente
  return (
    <LineChart
      data={data}
      height={300}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 50,
      }}
      width={1030}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="day" label={{ value: 'Días', position: 'bottom' }} />
      <YAxis label={{ value: 'Solicitudes', angle: -90, position: 'insideLeft' }} />
      <Tooltip />
      <Legend wrapperStyle={{ bottom: 0 }} />
      <Line dataKey="quotes" fill="#e81687" name="Todas" activeDot={{ r: 8 }} />
      <Line dataKey="offered" fill="#4292ff" name="Ofertadas" />
      <Line dataKey="rejected" fill="#febb2c" name="Rechazadas" />
      <Line dataKey="notOffered" fill="#22d3bd" name="Sin Respuesta" />
    </LineChart>
  );
};

export default MonthyCharts;
