// Importar librerías
import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';

// Importar rutas
import * as ROUTES from './../../constants/routes';

// Importar otros componentes
import { ReactComponent as AccountsIcon } from './icons/account.svg';
import { ReactComponent as ApplicationsIcon } from './icons/applications.svg';
import { ReactComponent as ArticlesIcon } from './icons/articles.svg';
import { ReactComponent as BankIcon } from './icons/bank.svg';
import { ReactComponent as BankOfficesIcon } from './icons/bankOffice.svg';
import { ReactComponent as HomeIcon } from './icons/home.svg';
import { ReactComponent as Logo } from './icons/LOGO FLIT.svg';
import { ReactComponent as SignOutIcon } from './icons/signOut.svg';
import { ReactComponent as MetricIcon } from './icons/metrics.svg';
import { ReactComponent as InterestRatesIcon } from './icons/rates.svg';
import { ReactComponent as ParameterizationIcon } from './icons/parameterization.svg';

// Importar subcomponente Sider
const { Sider } = Layout;

const CustomSider = () => {
  // Definir nueva instancia de useLocation
  const location = useLocation();

  // Definir state
  const [siderExpanded, setSiderExpanded] = useState(true);

  // Renderizar Sider para el caso que el usuario no esté autenticado
  if (
    location.pathname === ROUTES.SIGN_IN ||
    location.pathname === ROUTES.SIGN_UP ||
    location.pathname === ROUTES.PASSWORD_FORGET
  ) {
    return (
      <Sider
        theme="light"
        width={'640px'}
        className="session-sider"
        breakpoint={'xl'}
      >
        <div className={'sider-session-image'}></div>
      </Sider>
    );
  }

  // Renderizar componente
  return (
    <Sider
      id="side-menu"
      // collapsible
      breakpoint={'md'}
      onCollapse={(collapsed) => setSiderExpanded(!collapsed)}
    >
      {siderExpanded ? (
        <div className="logo">
          <Logo />
        </div>
      ) : (
        <div className="small-logo">
          <Logo />
        </div>
      )}
      <Menu
        theme="dark"
        defaultSelectedKeys={['1']}
        mode="inline"
        activeKey={[location.pathname]}
        selectedKeys={['/' + location.pathname.split('/')[1]]}
      >
        <Menu.Item key={ROUTES.HOME} className="side-item">
          <Link to={ROUTES.HOME}>
            <HomeIcon />
            {siderExpanded && <p>Inicio</p>}
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.ARTICLES_PAGE} className="side-item">
          <Link to={ROUTES.ARTICLES_PAGE}>
            <ArticlesIcon />
            {siderExpanded && <p>Actualidad Financiera</p>}
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.FINANTIAL_EDUCATION} className="side-item">
          <Link to={ROUTES.FINANTIAL_EDUCATION}>
            <ApplicationsIcon />
            {siderExpanded && <p>Educación Financiera</p>}
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.BANKS_PAGE} className="side-item">
          <Link to={ROUTES.BANKS_PAGE}>
            <BankIcon />
            {siderExpanded && <p>Institución Financiera</p>}
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.METRICS_PAGE} className="side-item">
          <Link to={ROUTES.METRICS_PAGE}>
            <MetricIcon />
            {siderExpanded && <p>Métricas</p>}
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.BANKS_PARAMETERIZATION_PAGE} className="side-item">
          <Link to={ROUTES.BANKS_PARAMETERIZATION_PAGE}>
            <ParameterizationIcon />
            {siderExpanded && <p>Parametrización Instituciones</p>}
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.BANK_OFFICES_PAGE} className="side-item">
          <Link to={ROUTES.BANK_OFFICES_PAGE}>
            <BankOfficesIcon />
            {siderExpanded && <p>Sucursales</p>}
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.APPLICATIONS_PAGE} className="side-item">
          <Link to={ROUTES.APPLICATIONS_PAGE}>
            <AccountsIcon />
            {siderExpanded && <p>Solicitudes</p>}
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.AVERAGE_INTEREST_RATE_PAGE} className="side-item">
          <Link to={ROUTES.AVERAGE_INTEREST_RATE_PAGE}>
            <InterestRatesIcon />
            {siderExpanded && <p>Tasas Interés Promedio</p>}
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.USERS_PAGE} className="side-item">
          <Link to={ROUTES.USERS_PAGE}>
            <AccountsIcon />
            {siderExpanded && <p>Usuarios FLIT</p>}
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.SIGN_OUT} className="side-item sign-out-item">
          <Link to={ROUTES.SIGN_OUT}>
            <SignOutIcon />
            {siderExpanded && <p>Cerrar Sesión</p>}
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default CustomSider;
