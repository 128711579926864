// Importar librerías
import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useDocument } from 'react-firebase-hooks/firestore';
import { Spin, Button, Table, Divider, Modal, message, Space } from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  EditOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';

// Importar context de firebase
import { FirebaseContext } from './../../context';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar otros componentes
import NewExecutiveModal from './NewExecutiveModal';
import NewFreeExecutiveModal from './NewFreeExecutiveModal';
import EditExecutiveModal from './EditExecutiveModal';

/**
 *
 * @param {*} word
 * Retorna una cadena con la primera letra en mayúscula.
 */
const capitalize = (word) => {
  return word[0].toUpperCase() + word.slice(1);
};

const BankOfficeItem = () => {
  // Obtener información del context
  const firebase = useContext(FirebaseContext);

  // Definir nueva instancia de useLocation
  const location = useLocation();

  // Definir state
  const [openExecutiveModal, setOpenExecutiveModal] = useState(null);
  const [openFreeExecutiveModal, setOpenFreeExecutiveModal] = useState(null);
  const [openEditExecutiveModal, setOpenEditExecutiveModal] = useState(null);
  const [openDeleteExecutiveModal, setOpenDeleteExecutiveModal] = useState(null);
  const [selectedExecutive, setSelectedExecutive] = useState(null);
  const [customLoading, setCustomLoading] = useState(false);

  // Obtener id de la sucursal
  const bankOfficeId = location.pathname.split('/')[2];

  // Obtener información de la sucursal
  const [bankOffice, loadingBankOffice, errorBankOffice] = useDocument(
    firebase.bankOfficeLogic.getBankOffice(bankOfficeId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  // Obtener información de los administradores de la sucursal
  const [
    bankOfficeAdmins,
    loadingBankOfficeAdmins,
    errorBankOfficeAdmins,
  ] = useDocument(firebase.bankOfficeLogic.getBankOfficeAdmins(bankOfficeId), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  // Obtener información de los ejecutivos de la sucursal
  const [
    bankOfficeExecutives,
    loadingBankOfficeExecutives,
    errorBankOfficeExecutives,
  ] = useDocument(firebase.bankOfficeLogic.getBankOfficeExecutives(bankOfficeId), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  /**
   *
   * @param {*} _
   * @param {*} record
   * Rederiza un componente con las acciones a realizar en el listado de sucursales.
   */
  const renderActions = (_, record) => {
    return (
      <Space>
        <EditOutlined title="Editar" onClick={() => setFields(record.id, true)} />
        <DeleteOutlined
          title="Eliminar"
          onClick={() => setFields(record.id, false)}
        />
      </Space>
    );
  };

  // Definir columnas de la tabla a mostrar
  const columns = [
    {
      title: 'NOMBRE',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) =>
        capitalize(record.get('firstName')) +
        ' ' +
        capitalize(record.get('lastName')),
      sorter: (a, b) =>
        (
          capitalize(a.get('firstName')) +
          ' ' +
          capitalize(a.get('lastName'))
        ).localeCompare(
          capitalize(b.get('firstName')) + ' ' + capitalize(b.get('lastName'))
        ),
    },
    {
      title: 'SUPERVISOR',
      dataIndex: 'bankOfficeBoss',
      key: 'bankOfficeBoss',
      render: (_, record) =>
        record.get('bankOfficeBoss') ? record.get('bankOfficeBoss') : '-',
      sorter: (a, b) => {
        let bossA = '-';
        let bossB = '-';
        if (a.get('bankOfficeBoss')) {
          bossA = a.get('bankOfficeBoss');
        }
        if (b.get('bankOfficeBoss')) {
          bossB = b.get('bankOfficeBoss');
        }
        return bossA.localeCompare(bossB);
      },
    },
    {
      title: 'SOLICITUDES ASIGNADAS',
      dataIndex: 'nAssignedApps',
      key: 'nAssignedApps',
      render: (_, record) => record.get('assignedApplications').length,
      sorter: (a, b) =>
        a.get('assignedApplications').length - b.get('assignedApplications').length,
    },
    {
      title: 'SOLICITUDES MANEJADAS',
      dataIndex: 'applicationTypes',
      key: 'applicationTypes',
      render: (_, record) => {
        if (!record.get('bankOfficeAppTypes')) {
          return '-';
        }
        return record
          .get('bankOfficeAppTypes')
          .map((item) => capitalize(item.replace('-', ' ')))
          .join(' / ');
      },
      sorter: (a, b) => {
        let appTypeA = '-';
        let appTypeB = '-';
        if (a.get('bankOfficeAppTypes')) {
          appTypeA = a
            .get('bankOfficeAppTypes')
            .map((item) => capitalize(item.replace('-', ' ')))
            .join(' / ');
        }
        if (b.get('bankOfficeAppTypes')) {
          b.get('bankOfficeAppTypes')
            .map((item) => capitalize(item.replace('-', ' ')))
            .join(' / ');
        }
        return appTypeA.localeCompare(appTypeB);
      },
    },
    {
      title: 'ACCIONES',
      dataIndex: 'actions',
      key: 'actions',
      render: renderActions,
    },
  ];

  // Renderizar componente de Spin si se está cargando la información
  if (loadingBankOffice || loadingBankOfficeAdmins || loadingBankOfficeExecutives)
    return <Spin className="spinner" />;

  // Renderizar texto de error en caso de que exista
  if (
    errorBankOffice ||
    errorBankOfficeAdmins ||
    errorBankOfficeExecutives ||
    (bankOffice && !bankOffice.exists)
  )
    return <p>Error</p>;

  /**
   *
   * @param {*} bankOfficeId
   * @param {*} bool
   * Actualiza la información de los estados seletectExecutive y
   * (openEditExecutiveModal o openDeleteExecutiveModal) según sea el caso.
   */
  const setFields = (bankOfficeExecutiveId, bool) => {
    // Obtener información del ejecutivo seleccionado
    const selected = bankOfficeExecutives.docs.filter(
      (item) => item.id === bankOfficeExecutiveId
    )[0];

    // Setear los valores
    setSelectedExecutive(selected);
    bool ? setOpenEditExecutiveModal(true) : setOpenDeleteExecutiveModal(true);
  };

  /**
   * Elimina a un ejecutivo de la sucursal y lo deja sin supervisor.
   */
  const deleteExecutive = async () => {
    // Habilitar carga de spinner
    setCustomLoading(true);

    // Intentar eliminar de la BD
    try {
      // Definir conjunto de valores
      const values = {
        bankOfficeId: selectedExecutive.get('bankOfficeId'),
        executiveId: selectedExecutive.id,
        email: selectedExecutive.get('email'),
      };

      await firebase.bankOfficeLogic.deleteBankOfficeExecutive(values);

      // Setear información del modal
      setOpenDeleteExecutiveModal(false);

      // Mostrar mensaje de éxito
      message.success('Ejecutivo eliminado exitosamente');
    } catch (error) {
      message.error(error.message);
    }

    // Deshabilitar carga de spinner
    setCustomLoading(false);
  };

  // Renderizar componente
  return (
    <div id="bank-item">
      <Spin spinning={customLoading}>
        <div className={'bank-office-head'}>
          <Button type="link" href={ROUTES.BANK_OFFICES_PAGE}>
            <ArrowLeftOutlined />
            Volver
          </Button>
        </div>
        <div className={'bank-head'}>
          <h1>{bankOffice.get('bankOfficeName')} / Ejecutivos</h1>
          <div>
            <Button
              type={'primary'}
              icon={<PlusOutlined />}
              onClick={() => setOpenExecutiveModal(true)}
            >
              Nuevo Ejecutivo
            </Button>
            <Button
              type={'primary'}
              icon={<PlusOutlined />}
              onClick={() => setOpenFreeExecutiveModal(true)}
            >
              Elegir Ejecutivo Libre
            </Button>
          </div>
        </div>
        <Divider />
        <div className={'table'}>
          <NewExecutiveModal
            openModal={openExecutiveModal}
            setOpenModal={setOpenExecutiveModal}
            bankOfficeAdmins={bankOfficeAdmins}
          />
          <NewFreeExecutiveModal
            openModal={openFreeExecutiveModal}
            setOpenModal={setOpenFreeExecutiveModal}
            bankId={bankOffice.get('bankId')}
            bankOfficeAdmins={bankOfficeAdmins}
          />
          <EditExecutiveModal
            openModal={openEditExecutiveModal}
            setOpenModal={setOpenEditExecutiveModal}
            selectedExecutive={selectedExecutive}
            bankOfficeAdmins={bankOfficeAdmins}
          />
          <Modal
            onCancel={() => {
              setOpenDeleteExecutiveModal(null);
            }}
            footer={
              <div id="bank-modal-footer">
                <Button onClick={() => deleteExecutive()}>Eliminar</Button>
              </div>
            }
            visible={openDeleteExecutiveModal}
          >
            <div id="bank-modal-body">
              <h3>Eliminar Ejecutivo</h3>
              <p>
                Esta acción eliminará al ejecutivo de la sucursal y lo dejará sin un
                supervisor. Seguro que desea realizar esta acción?
              </p>
            </div>
          </Modal>
          <Table
            dataSource={bankOfficeExecutives.docs}
            columns={columns}
            rowKey={(d) => d.id}
          />
        </div>
      </Spin>
    </div>
  );
};

export default BankOfficeItem;
