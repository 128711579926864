// Importar librerías
import React, { useState, useContext } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Button, Table, Divider, Space } from 'antd';
import {
  PlusOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar context de firebase
import { FirebaseContext } from '../../context';

// Importar otros componentes
import NewAdminModal from './NewAdminModal';
import EditAdminModal from './EditAdminModal';
import DeleteAdminModal from './DeleteAdminModal';
import EditBankOfficeModal from './EditBankOfficeModal';
import DeleteBankOfficeModal from './DeleteBankOfficeModal';

/**
 *
 * @param {*} word
 * Retorna una cadena con la primera letra en mayúscula.
 */
const capitalize = (word) => {
  return word[0].toUpperCase() + word.slice(1);
};

const BankOfficeList = () => {
  // Definir context de firebase
  const firebase = useContext(FirebaseContext);

  // Obtener información de las sucursales
  const [bankOffices, loading] = useCollection(
    firebase.bankOfficeLogic.getBankOffices(),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  // Definir state
  const [openNewAdminModal, setOpenNewAdminModal] = useState(null);
  const [openEditAdminModal, setOpenEditAdminModal] = useState(null);
  const [openDeleteAdminModal, setOpenDeleteAdminModal] = useState(null);
  const [openEditOfficeModal, setOpenEditOfficeModal] = useState(null);
  const [openDeleteOfficeModal, setOpenDeleteOfficeModal] = useState(null);
  const [selectedBankOffice, setSelectedBankOffice] = useState(null);

  /**
   *
   * @param {*} _
   * @param {*} record
   * Rederiza un componente con las acciones a realizar en el listado de sucursales.
   */
  const renderActions = (_, record) => {
    return (
      <Space>
        <Link to={`${ROUTES.BANK_OFFICES_PAGE}/${record.id}`}>
          <EyeOutlined title="Ver" />
        </Link>
        <EditOutlined title="Editar" onClick={() => setFields(record.id, true)} />
        <DeleteOutlined
          title="Eliminar"
          onClick={() => setFields(record.id, false)}
        />
      </Space>
    );
  };

  // Definir columnas a mostrar en la tabla
  const columns = [
    {
      title: 'NOMBRE',
      dataIndex: 'bankOfficeName',
      key: 'bankOfficeName',
      render: (_, record) => record.get('bankOfficeName'),
      sorter: (a, b) =>
        a.get('bankOfficeName').localeCompare(b.get('bankOfficeName')),
    },
    {
      title: 'BANCO',
      dataIndex: 'bankName',
      key: 'bankName',
      render: (_, record) => record.get('bankName'),
      sorter: (a, b) => a.get('bankName').localeCompare(b.get('bankName')),
    },
    {
      title: 'NUMERO DE ADMINISTRADORES',
      dataIndex: 'nAdmins',
      key: 'nAdmins',
      render: (_, record) => record.get('bankOfficeAdmins').length,
      sorter: (a, b) =>
        a.get('bankOfficeAdmins').length - b.get('bankOfficeAdmins').length,
    },
    {
      title: 'NUMERO DE EJECUTIVOS',
      dataIndex: 'nExecutives',
      key: 'nExecutives',
      render: (_, record) => record.get('bankOfficeExecutives').length,
      sorter: (a, b) =>
        a.get('bankOfficeExecutives').length - b.get('bankOfficeExecutives').length,
    },
    {
      title: 'SOLICITUDES MANEJADAS',
      dataIndex: 'applicationTypes',
      key: 'applicationTypes',
      render: (_, record) => {
        if (!record.get('bankOfficeAppTypes')) {
          return '-';
        }
        return record
          .get('bankOfficeAppTypes')
          .map((item) => capitalize(item.replace('-', ' ')))
          .join(' / ');
      },
      sorter: (a, b) => {
        let appTypeA = '-';
        let appTypeB = '-';
        if (a.get('bankOfficeAppTypes')) {
          appTypeA = a
            .get('bankOfficeAppTypes')
            .map((item) => capitalize(item.replace('-', ' ')))
            .join(' / ');
        }
        if (b.get('bankOfficeAppTypes')) {
          b.get('bankOfficeAppTypes')
            .map((item) => capitalize(item.replace('-', ' ')))
            .join(' / ');
        }
        return appTypeA.localeCompare(appTypeB);
      },
    },
    {
      title: 'ACCIONES',
      dataIndex: 'actions',
      key: 'actions',
      render: renderActions,
    },
  ];

  /**
   *
   * @param {*} bankOfficeId
   * @param {*} bool
   * Actualiza la información de los estados seletectBankOffice y
   * (openEditOfficeModal o openDeleteOfficeModal) según sea el caso.
   */
  const setFields = (bankOfficeId, bool) => {
    // Obtener información de la sucursal seleccionada
    const selected = bankOffices.docs.filter((item) => item.id === bankOfficeId)[0];

    // Setear los valores
    setSelectedBankOffice(selected);
    bool ? setOpenEditOfficeModal(true) : setOpenDeleteOfficeModal(true);
  };

  // Renderizar componente
  return (
    <div className={'banks-list'}>
      <div id={'banks-head'}>
        <h1>Sucursales</h1>
        <div>
          <h1 style={{ textAlign: 'center' }}>Administrador</h1>
          <Button
            type={'primary'}
            icon={<PlusOutlined title="Agregar" />}
            onClick={() => setOpenNewAdminModal(true)}
          >
            Nuevo
          </Button>
          <Button
            type={'primary'}
            icon={<EditOutlined title="Editar" />}
            onClick={() => setOpenEditAdminModal(true)}
            className="last-botton"
          >
            Editar
          </Button>
          <Button
            type={'primary'}
            icon={<DeleteOutlined title="Eliminar" />}
            onClick={() => setOpenDeleteAdminModal(true)}
            className="last-botton danger"
          >
            Eliminar
          </Button>
        </div>
      </div>
      <Divider />
      <div id={'bank-main'}>
        <NewAdminModal
          openModal={openNewAdminModal}
          setOpenModal={setOpenNewAdminModal}
          bankOffices={bankOffices}
        />
        <EditAdminModal
          openModal={openEditAdminModal}
          setOpenModal={setOpenEditAdminModal}
          bankOffices={bankOffices}
        />
        <DeleteAdminModal
          openModal={openDeleteAdminModal}
          setOpenModal={setOpenDeleteAdminModal}
          bankOffices={bankOffices}
        />
        <EditBankOfficeModal
          openModal={openEditOfficeModal}
          setOpenModal={setOpenEditOfficeModal}
          bankOffice={selectedBankOffice}
        />
        <DeleteBankOfficeModal
          openModal={openDeleteOfficeModal}
          setOpenModal={setOpenDeleteOfficeModal}
          bankOffice={selectedBankOffice}
        />
        <Table
          loading={loading}
          columns={columns}
          dataSource={bankOffices ? bankOffices.docs : []}
          rowKey={(r) => r.id}
        />
      </div>
    </div>
  );
};

export default BankOfficeList;
