// Importar librerías
import React, { useState } from 'react';
import {
  message,
  Input,
  Form,
  Button,
  Modal,
  Row,
  Col,
  Select,
  InputNumber,
} from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import _ from 'lodash';

// Definir meses y años
const months = moment.months().map((month) => _.capitalize(month));
const years = ['2019', '2020', '2021', '2022', '2023', '2024', '2025'];

// Importar subcomponente Option
const { Option } = Select;

const NewGraphDataModal = ({ openModal, setModal, firebase }) => {
  // Definir state
  const [loading, setLoading] = useState(false);
  const [consumeRateFocus, setConsumeRateFocus] = useState(false);
  const [mortgageRateFocus, setMortgageRateFocus] = useState(false);

  // Crear instancias de useForm
  const [interestRateFormInstance] = Form.useForm();

  /**
   *
   * @param {*} values
   * Almacena la información de la nueva sucursal que se está creando en la BD.
   */
  const onFinish = async (values) => {
    // Habilitar carga del spinner
    setLoading(true);

    // Almacenar en la Base de Datos
    try {
      await firebase.interestRateLogic.createInterestRate(values);

      // Mostrar mensaje de éxito
      message.success('Información agregada correctamente');

      // Limpiar formulario
      interestRateFormInstance.resetFields();

      // Setear valor para el state Modal
      setModal(false);
    } catch (error) {
      message.error(error.message);
    }

    // Deshabilitar carga del spinner
    setLoading(false);
  };

  /**
   *
   * @param {*} errorInfo
   * Muestra un error en caso de que no se haya podido guardar la información en la BD.
   */
  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  // Renderizar componente
  return (
    <Modal
      onCancel={() => {
        setModal(null);
        interestRateFormInstance.resetFields();
      }}
      confirmLoading={loading}
      footer={<div id="bank-modal-footer"></div>}
      visible={openModal}
    >
      <div id="bank-modal-body">
        <h3>Agregar Nuevo Dato de Interés</h3>
        <Form
          form={interestRateFormInstance}
          className={'modal-form'}
          layout={'vertical'}
          name="link_form"
          onFinish={(values) => onFinish(values)}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={(0, 12)}>
            <Col span={12}>
              <Form.Item
                label="Año"
                name="year"
                rules={[{ required: true, message: 'Agregue el año' }]}
              >
                <Select placeholder="Selecciona">
                  {years.map((year) => (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Mes"
                name="month"
                rules={[{ required: true, message: 'Agregue el mes' }]}
              >
                <Select placeholder="Selecciona">
                  {months.map((month) => (
                    <Option key={month} value={month}>
                      {month}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Semana"
            name="week"
            rules={[{ required: true, message: 'Agregue la semana' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Tasa de Interés de Consumo"
            name="consumeRate"
            rules={[
              {
                required: true,
                message: 'Agregue el valor de la tasa de interés de consumo',
              },
            ]}
          >
            <InputNumber
              min={0.0}
              step={0.01}
              precision={2}
              type={consumeRateFocus ? 'number' : 'string'}
              formatter={(value) =>
                consumeRateFocus ? value : value.replace('.', ',')
              }
              parser={(value) =>
                value.replace(',', '.').replace(/^(0.)|([^0-9.])/, '')
              }
              onFocus={() => setConsumeRateFocus(true)}
              onBlur={() => setConsumeRateFocus(false)}
            />
          </Form.Item>
          <Form.Item
            label="Tasa de Interés Hipotecaria"
            name="mortgageRate"
            rules={[
              {
                required: true,
                message: 'Agregue el valor de la tasa de interés hipotecaria',
              },
            ]}
          >
            <InputNumber
              min={0.0}
              precision={2}
              step={0.01}
              formatter={(value) =>
                mortgageRateFocus ? value : value.replace('.', ',')
              }
              type={mortgageRateFocus ? 'number' : 'string'}
              parser={(value) =>
                value.replace(',', '.').replace(/^(0.)|([^0-9.])/, '')
              }
              onFocus={() => setMortgageRateFocus(true)}
              onBlur={() => setMortgageRateFocus(false)}
            />
          </Form.Item>
          <Form.Item className={'button-holder'}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Crear
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default NewGraphDataModal;
