// Importar librerías
import React, { useState } from 'react';

// Importar otros componentes
import TakenApplicationAnswerShowHipotecario from './TakenApplicationAnswerShowHipotecario';
import TakenApplicationAnswerShowConsumo from './TakenApplicationAnswerShowConsumo';

/**
 *
 * @param {*} application
 * Obtiene respuesta ganadora del banco.
 */
const getBankAnswer = (application) => {
  if (!application) return null;

  const winnerAnswer = application.get('winner');

  if (!winnerAnswer) {
    return null;
  }

  return winnerAnswer;
};

const TakenApplicationAnswerShowIndex = ({ applicationType, application }) => {
  // null o la respuesta
  const [currentBankAnswer, ,] = useState(getBankAnswer(application));

  // Renderizar según sea el caso
  switch (applicationType) {
    case 'portabilidad-hipotecario':
    case 'hipotecario':
      return (
        <TakenApplicationAnswerShowHipotecario
          application={application}
          currentBankAnswer={currentBankAnswer}
        />
      );
    case 'portabilidad-consumo':
    case 'consumo':
    case 'automotriz':
      return (
        <TakenApplicationAnswerShowConsumo
          application={application}
          currentBankAnswer={currentBankAnswer}
        />
      );

    default:
      return <div>Error</div>;
  }
};

export default TakenApplicationAnswerShowIndex;
