import React from 'react';

import { FirebaseContext } from '../../context';
import { Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const SignOut = () => {
  const firebase = React.useContext(FirebaseContext);
  firebase.authLogic.doSignOut();

  return <Redirect to={ROUTES.SIGN_IN} />;
};

export default SignOut;
