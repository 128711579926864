class authenticationLogic {
  constructor(app, auth, userLogic) {
    this.auth = auth;
    this.userLogic = userLogic;
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, firstName, lastName, roles, password) =>
    this.auth
      .createUserWithEmailAndPassword(email, password)
      .then((authUser) => {
        this.userLogic.newUser(authUser.user.uid, {
          firstName,
          lastName,
          email,
          roles,
        });
      })
      .then(() => {
        this.doSendEmailVerification();
      });

  doSignInWithEmailAndPassword = (email, password) => {
    const result = this.auth.signInWithEmailAndPassword(email, password);
    return result;
  };

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () => {
    let url_redirect;
    switch (process.env.REACT_APP_ENV) {
      case 'dev':
        url_redirect = process.env.REACT_APP_DEV_CONFIRMATION_EMAIL_REDIRECT;
        break;
      case 'staging':
        url_redirect = process.env.REACT_APP_STAGE_CONFIRMATION_EMAIL_REDIRECT;

        break;
      case 'prod':
        url_redirect = process.env.REACT_APP_PROD_CONFIRMATION_EMAIL_REDIRECT;
        break;

      default:
        break;
    }

    return this.auth.currentUser.sendEmailVerification({
      url: url_redirect,
    });
  };

  doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password);
}

export default authenticationLogic;
