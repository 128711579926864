// Importar librerías
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

// Importar utilidades
import { withAuthorization } from '../../utils';

// Importar otros componentes
import BankOfficeList from './BankOfficeList';
import BankOfficeItem from './BankOfficeItem';

// Importar rutas y condicionales
import * as ROUTES from '../../constants/routes';
import { adminUserCondition } from '../../constants/conditions';

const BankOfficesPage = () => (
  <div>
    <Switch>
      <Route exact path={ROUTES.BANK_OFFICES_PAGE} component={BankOfficeList} />
      <Route exact path={ROUTES.BANK_OFFICE_PAGE} component={BankOfficeItem} />
    </Switch>
  </div>
);

export default compose(withAuthorization(adminUserCondition))(BankOfficesPage);
