import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import * as ROUTES from '../../constants/routes';
import { Switch, Route } from 'react-router-dom';

import FinancialEducationItem from './FinantialEducationItem';
import { Input } from 'antd';

const markdownExplanation = `

### ¿Que es Markdown?

Es un lenguaje de marcado, como html, para escribir texto con formato facilmente
- [Referencia](https://markdown.es/)
- [Tutorial](https://www.markdowntutorial.com/)
- [CheatSheet](https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet)
- [Editor](https://hackmd.io/)

--- 

### Ejemplo

A continuación hay un editor de ejemplo

`;

const explanation = `
---


### Como agregar una noticia de eduación financiera

1. Escribir la noticia en formato markdown
2. Agregarle al final de cada linea lo siguiente "_n" (Sin las comillas)
  
  2.1 Esto se puede hacer [aqui](https://textmechanic.com/text-tools/basic-text-tools/add-prefixsuffix-into-line/)
  
  2.2 Ingresar ahi, copiar y pegar todo lo que se escribio en la casilla de arriba
  
  2.3 Agregar un sufijo _n

  2.4 ![imagen de ejemplo](https://i.imgur.com/wvsWVnj.png)

3. Ir a la console de firebase
  
  3.1 Ingresar a https://firebase.google.com/

  3.2 Fijarse que se está ingresando con la cuenta flit.desarrollo@gmail.com

  3.3 Apretar "Ir a la consola", arriba a la derecha ![imagen](https://imgur.com/4U3KRtt.png)

  3.4 Elegimos el proyecto en donde queramos subir una noticia (flit-prod seria flit.cl, flit-dev seria https://flit-dev.firebaseapp.com/ usado para pruebas) ![proyectos](https://i.imgur.com/ttyXo21.png)

  3.5 En el menu de la izquierda, ir a Database ![imagen](https://i.imgur.com/obPV2bg.png)

  3.6 Elegir la coleccion financialEducation ![imagen](https://i.imgur.com/0swbdvs.png)

  3.7 Apretar la opcion Add document

    3.7.1 En document Id poner la url que se quiera tener. Por ejemplo, si se quiere que la url sea flit.cl/educacion-financiera/como-comprar-una-casa, ingresar como-comprar-una-casa

    3.7.2 Agregar los siguientes campos, con el boton + que hay, ponerle los titulos correspondientes en el campo Field, dejar el tipo como String y ponerle un valor.

    3.7.3 Los campos son: title: El titulo del articulo, content: El contenido del articulo, lo que escribimos antes, con los _n agregador. imgUrl: La url de una imagen que queremos que se muestre junto al articulo. summary: Un pequeño resumen (En texto, no formato markdown) para mostrar en la pagina principal de flit. dateCreated: En este cambiamos el tipo de String por timestamp, y seleccionamos la fecha de hoy.
    ![imagen](https://imgur.com/KDKDe9X.png)
    
    
    3.7.4 Los campos pueden ser editados despues, los nombres de los campos no. Si se escribio algo mal, hay que borrar el campo erroneo, y agregarlo de nuevo. __Fijarse muy bien que esten todos y bien escritos__
    
    3.7.5 Deberia quedar todo asi. 
    ![img](https://imgur.com/AHZn95C.png)
    

Listo, ahora el nuevo articulo de educacion financiera deberia aparecer en el landing page, revisar que este todo ok alla.


`;

const FinancialEducationMain = () => {
  const [markdownExample, setMarkdownExample] = useState(
    `### Este es el ejemplo de markdown
Escribir aqui`
  );
  return (
    <div>
      <div className={'finantial-head'}>
        <h1>¿Como agregar un articulo de educación financiera?</h1>
      </div>
      <div className={'finantial-explanation'}>
        <ReactMarkdown source={markdownExplanation} />
        <div className={'example'}>
          <Input.TextArea
            onChange={(e) => setMarkdownExample(e.target.value)}
            placeholder={'Escribe aqui'}
            value={markdownExample}
          ></Input.TextArea>
          <div>
            <ReactMarkdown
              source={markdownExample}
              escapeHtml={false}
              className={'example-div'}
            />
          </div>
        </div>
        {/* <ReactMarkdown source={explanation} /> */}
      </div>
    </div>
  );
};

const FinantialEducationPage = () => (
  <div className={'financial-education-page'}>
    <Switch>
      {/* Carga por defecto la lista */}
      <Route
        exact
        path={ROUTES.FINANTIAL_EDUCATION}
        component={FinancialEducationMain}
      />
      <Route
        exact
        path={ROUTES.FINANCIAL_EDUCATION_PAGE}
        component={FinancialEducationItem}
      />
    </Switch>
  </div>
);

export default FinantialEducationPage;
