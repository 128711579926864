import React, { useContext } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useLocation } from 'react-router-dom';
import { FirebaseContext } from './../../context';

import ReactMarkdown from 'react-markdown';

const FinancialEducationItem = () => {
  const firebase = useContext(FirebaseContext);
  const location = useLocation().pathname.split('educacion')[1];

  console.log(location);
  const [value, loading, error] = useDocument(
    firebase.db.doc(`financialEducation${location}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  return (
    <div id="financial-article">
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading && <span>Document: Loading...</span>}
      {value && (
        <span>
          <ReactMarkdown
            escapeHtml={false}
            source={value
              .get('content')
              .split('_n')
              .join('\n\n')}
          />
        </span>
      )}
    </div>
  );
};

export default FinancialEducationItem;
