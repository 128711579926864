// Importar librerías
import React, { useState, useContext } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Button, Table, Divider, Space } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

// Importar context de firebase
import { FirebaseContext } from '../../context';

// Importar otros componentes
import NewGraphDataModal from './NewGraphDataModal';
import EditGraphDataModal from './EditGraphDataModal';
import DeleteGraphDataModal from './DeleteGraphDataModal';

const AverageInterestRate = () => {
  // Definir context de firebase
  const firebase = useContext(FirebaseContext);

  // Obtener información de los datos de interés
  const [interestRate, loading] = useCollection(
    firebase.interestRateLogic.getInterestRates().orderBy('createdAt', 'desc'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  // Definir state
  const [openNewGraphDataModal, setOpenGraphDataModal] = useState(null);
  const [openEditGraphDataModal, setOpenEditGraphDataModal] = useState(null);
  const [openDeleteGraphDataModal, setOpenDeleteGraphDataModal] = useState(null);
  const [currentDataId, setCurrentDataId] = useState(null);

  /**
   *
   * @param {*} _
   * @param {*} record
   * Rederiza un componente con las acciones a realizar en el listado de sucursales.
   */
  const renderActions = (_, record) => {
    return (
      <Space>
        <EditOutlined title="Editar" onClick={() => setFields(record.id, true)} />
        <DeleteOutlined
          title="Eliminar"
          onClick={() => setFields(record.id, false)}
        />
      </Space>
    );
  };

  // Definir columnas a mostrar en la tabla
  const columns = [
    {
      title: 'AÑO',
      dataIndex: 'year',
      key: 'year',
      render: (_, record) => record.get('year'),
      sorter: (a, b) => a.get('year').localeCompare(b.get('year')),
    },
    {
      title: 'MES',
      dataIndex: 'month',
      key: 'month',
      render: (_, record) => record.get('month'),
      sorter: (a, b) => a.get('month').localeCompare(b.get('month')),
    },
    {
      title: 'SEMANA',
      dataIndex: 'week',
      key: 'week',
      render: (_, record) => record.get('week'),
      sorter: (a, b) => a.get('week').length - b.get('week'),
    },
    {
      title: 'INTERÉS DE CONSUMO (%)',
      dataIndex: 'consumeRate',
      key: 'consumeRate',
      render: (_, record) => record.get('consumeRate').toString().replace('.', ','),
      sorter: (a, b) =>
        a
          .get('consumeRate')
          .toString()
          .replace('.', ',')
          .localeCompare(b.get('consumeRate').toString().replace('.', ',')),
    },
    {
      title: 'INTERÉS HIPOTECARIO (%)',
      dataIndex: 'mortgageRate',
      key: 'mortgageRate',
      render: (_, record) => record.get('mortgageRate').toString().replace('.', ','),
      sorter: (a, b) =>
        a
          .get('mortgageRate')
          .toString()
          .replace('.', ',')
          .localeCompare(b.get('mortgageRate').toString().replace('.', ',')),
    },
    {
      title: 'ACCIONES',
      dataIndex: 'actions',
      key: 'actions',
      render: renderActions,
    },
  ];

  /**
   *
   * @param {*} dataInfoId
   * @param {*} bool
   * Actualiza la información de los estados setOpenEditGraphDataModal u setOpenDeleteGraphDataModal
   * según sea el caso.
   */
  const setFields = (dataInfoId, bool) => {
    // Setear los valores
    setCurrentDataId(dataInfoId);
    bool ? setOpenEditGraphDataModal(true) : setOpenDeleteGraphDataModal(true);
  };

  // Renderizar componente
  return (
    <div className={'banks-list'}>
      <div id={'banks-head'}>
        <h1>Tasas de Interés</h1>
        <Button
          type={'primary'}
          icon={<PlusOutlined />}
          onClick={() => setOpenGraphDataModal(true)}
        >
          Agregar Dato
        </Button>
      </div>
      <Divider />
      <div id={'bank-main'}>
        <NewGraphDataModal
          openModal={openNewGraphDataModal}
          setModal={setOpenGraphDataModal}
          firebase={firebase}
        />
        <EditGraphDataModal
          openModal={openEditGraphDataModal}
          setModal={setOpenEditGraphDataModal}
          firebase={firebase}
          currentDataId={currentDataId}
        />
        <DeleteGraphDataModal
          openModal={openDeleteGraphDataModal}
          setModal={setOpenDeleteGraphDataModal}
          firebase={firebase}
          currentDataId={currentDataId}
        />
        <Table
          loading={loading}
          columns={columns}
          dataSource={interestRate ? interestRate.docs : []}
          rowKey={(r) => r.id}
        />
      </div>
    </div>
  );
};

export default AverageInterestRate;
