// Importar librerías
import React, { useState } from 'react';

// Importar otros componentes
import TakenApplicationAnswerShowHipotecario from './TakenApplicationAnswerShowHipotecario';
import TakenApplicationAnswerShowConsumo from './TakenApplicationAnswerShowConsumo';

// Definir objeto con la información de los parámetros a evaluar para la solicitud
const paramToValueConsumo = {
  cuota: 'cuotaMensual1',
  credito: 'costoTotal1',
};

/**
 *
 * @param {*} application
 * @param {*} applicationType
 * Obtiene la información del banco para una solicitud de entrada.
 */
const getBankAnswer = (application, applicationType) => {
  if (!application) return null;

  const allAnswers = application.get('answers');

  // Sin ofertas
  if (!allAnswers[0]) return null;
  const offers = allAnswers.filter((d) => d.status === 'offer');

  // Solo rechazos
  if (!offers[0]) return null;

  const param = application.get('bestAnswerParam');

  let bestAnswer = offers.reduce((prev, current) => {
    if (
      applicationType === 'hipotecario' ||
      applicationType === 'portabilidad-hipotecario'
    ) {
      return prev.answer[param] > current.answer[param] ? current : prev;
    } else if (
      applicationType === 'consumo' ||
      applicationType === 'portabilidad-consumo'
    ) {
      return prev.answer[paramToValueConsumo[param]] >
        current.answer[paramToValueConsumo[param]]
        ? current
        : prev;
    }
  });

  if (!bestAnswer) return null;

  return bestAnswer;
};

const BestAnswerShowIndex = ({ applicationType, application }) => {
  // null o la respuesta
  const [currentBankAnswer, ,] = useState(
    getBankAnswer(application, applicationType)
  );

  if (!currentBankAnswer) {
    return <p>Aun no hay respuestas con ofertas para esta solicitud</p>;
  }

  // Renderizar componente según sea el caso
  switch (applicationType) {
    case 'portabilidad-hipotecario':
    case 'hipotecario':
      return (
        <TakenApplicationAnswerShowHipotecario
          application={application}
          currentBankAnswer={currentBankAnswer}
        />
      );
    case 'portabilidad-consumo':
    case 'consumo':
    case 'automotriz':
      return (
        <TakenApplicationAnswerShowConsumo
          application={application}
          currentBankAnswer={currentBankAnswer}
        />
      );

    default:
      return <div>Error</div>;
  }
};

export default BestAnswerShowIndex;
