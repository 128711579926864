// Importar librerías
import React, { useState, useContext } from 'react';
import { message, Form, Button, Modal, Select, Checkbox } from 'antd';

// Importar context de firebase
import { FirebaseContext } from './../../context';

// Importar constantes
import { appOptionsAdminExecutive } from '../../constants/appTypes';

// Importar subcomponente Option
const { Option } = Select;

const EditExecutiveModal = ({
  openModal,
  setOpenModal,
  selectedExecutive,
  bankOfficeAdmins,
}) => {
  // Obtener información del context de firebase
  const firebase = useContext(FirebaseContext);

  // Definir state
  const [loading, setLoading] = useState(false);
  const [appOptions, setAppOptions] = useState([]);

  // Crear instancias de useForm
  const [editExecutiveFormInstance] = Form.useForm();

  /**
   *
   * @param {*} changedValue
   * @param {*} allValues
   * Detecta los cambios que ocurran con cada campo del formulario.
   */
  const onValuesChange = (changedValue, allValues) => {
    // Obtener información del campo que se está cambiando
    const field = Object.keys(changedValue)[0];

    // Reinicializar selector de tipos de solicitudes cuando se haga un cambio en el administrador
    if (field === 'email') {
      const { email } = allValues;

      // Obtener información del administrador seleccionado
      const getSelectedAdmin = bankOfficeAdmins.docs.filter(
        (item) => item.get('email') === email
      )[0];

      // Obtener información de los tipos de solicitudes que puede manejar el administrador
      let executiveAppTypes = [];
      getSelectedAdmin.get('bankOfficeAppTypes').forEach((type) => {
        executiveAppTypes.push(appOptionsAdminExecutive[type]);
      });

      // Setear información
      setAppOptions(executiveAppTypes);

      // Inicializar selector de tipos de Aplicación
      editExecutiveFormInstance.setFieldsValue({ bankOfficeAppTypes: [] });
    }
  };

  /**
   *
   * @param {*} values
   * Almacena la información del nuevo administrador que se está creando en la BD.
   */
  const onFinish = async (values) => {
    // Habilitar carga del spinner
    setLoading(true);

    // Agregar información adicional a los valores de entrada
    values = {
      ...values,
      executiveId: selectedExecutive.id,
    };

    // Intentar almacenar en la BD
    try {
      await firebase.bankOfficeLogic.updateBankOfficeExecutive(values);

      // Mostrar mensaje de éxito
      message.success('Ejecutivo actualizado exitosamente');

      // Limpiar campos de formulario
      editExecutiveFormInstance.resetFields();
      setAppOptions([]);

      // Terminar ejecución y ocultar modal
      setOpenModal(false);
    } catch (error) {
      message.error(error.message);
    }

    // Deshabilitar carga del spinner
    setLoading(false);
  };

  /**
   *
   * @param {*} errorInfo
   * Muestra un error en caso de que no se haya podido guardar la información en la BD.
   */
  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  // Renderizar componente
  return (
    <Modal
      onCancel={() => {
        editExecutiveFormInstance.resetFields();
        setAppOptions([]);
        setOpenModal(null);
      }}
      confirmLoading={loading}
      footer={<div id="bank-modal-footer"></div>}
      visible={openModal}
    >
      <div id="bank-modal-body">
        <h3>Cambiar Administrador Asignado</h3>
        <Form
          form={editExecutiveFormInstance}
          className={'modal-form'}
          layout={'vertical'}
          name="link_form"
          onValuesChange={onValuesChange}
          onFinish={(values) => onFinish(values)}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Email del Nuevo Administrador (Supervisor)"
            name="email"
            rules={[
              { required: true, message: 'Seleccione el email del administrador' },
            ]}
          >
            <Select placeholder="Selecciona">
              {bankOfficeAdmins &&
                selectedExecutive &&
                bankOfficeAdmins.docs
                  .filter(
                    (item) =>
                      item.get('email') !== selectedExecutive.get('bankOfficeBoss')
                  )
                  .map((item) => (
                    <Option key={item.id} value={item.get('email')}>
                      {item.get('email')}
                    </Option>
                  ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Tipos de Solicitudes"
            name="bankOfficeAppTypes"
            rules={[
              {
                required: true,
                message:
                  'Seleccione al menos un tipo de solicitud que maneje el ejecutivo',
              },
            ]}
          >
            <Checkbox.Group options={appOptions} />
          </Form.Item>
          <Form.Item className={'button-holder'}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Actualizar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditExecutiveModal;
