// Importar librerías
import React, { useState, useContext } from 'react';
import { message, Select, Form, Button, Modal, Checkbox } from 'antd';
import { useCollection } from 'react-firebase-hooks/firestore';

// Importar context de firebase
import { FirebaseContext } from '../../context';

// Importar constantes
import { appOptionsAdminExecutive } from '../../constants/appTypes';

// Importar subcomponente Option
const { Option } = Select;

const NewFreeExecutiveModal = ({
  openModal,
  setOpenModal,
  bankId,
  bankOfficeAdmins,
}) => {
  // Obtener información del context de firebase
  const firebase = useContext(FirebaseContext);

  // Obtener información de los usuarios que tenga el id del banco asociado y cuyo rol
  // sea BANKEXECUTIVE
  const [freeExecutives] = useCollection(
    firebase.bankOfficeLogic
      .getUsers()
      .where('bankId', '==', bankId)
      .where('bankOfficeId', '==', '')
      .where('roles.BANKEXECUTIVE', '==', 'BANKEXECUTIVE'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  // Definir state
  const [loading, setLoading] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [appOptions, setAppOptions] = useState([]);

  // Crear instancias de useForm
  const [freeExecutiveFormInstance] = Form.useForm();

  /**
   *
   * @param {*} changedValue
   * @param {*} allValues
   * Detecta los cambios que ocurran con cada campo del formulario.
   */
  const onValuesChange = (changedValue, allValues) => {
    // Obtener información del campo que se está cambiando
    const field = Object.keys(changedValue)[0];

    // Reinicializar selector de tipos de solicitudes cuando se haga un cambio en el administrador
    if (field === 'adminEmail') {
      const { adminEmail } = allValues;

      // Obtener información del administrador seleccionado
      const getSelectedAdmin = bankOfficeAdmins.docs.filter(
        (item) => item.get('email') === adminEmail
      )[0];

      // Obtener información de los tipos de solicitudes que puede manejar el administrador
      let executiveAppTypes = [];
      getSelectedAdmin.get('bankOfficeAppTypes').forEach((type) => {
        executiveAppTypes.push(appOptionsAdminExecutive[type]);
      });

      // Setear información
      setSelectedAdmin(getSelectedAdmin);
      setAppOptions(executiveAppTypes);

      // Inicializar selector de tipos de Applicación
      freeExecutiveFormInstance.setFieldsValue({ bankOfficeAppTypes: [] });
    }
  };

  /**
   *
   * @param {*} values
   * Almacena la información del nuevo administrador que se está creando en la BD.
   */
  const onFinish = async (values) => {
    // Habilitar carga del spinner
    setLoading(true);

    // Obtener información del ejecutivo seleccionado
    const selectedExecutive = freeExecutives.docs.filter(
      (item) => item.get('email') === values.executiveEmail
    )[0];

    // Agregar información adicional a los valores de entrada
    values = {
      ...values,
      executiveId: selectedExecutive.id,
      bankOfficeId: selectedAdmin.get('bankOfficeId'),
      bankOfficeName: selectedAdmin.get('bankOfficeName'),
    };

    // Intentar almacenar en la BD
    try {
      await firebase.bankOfficeLogic.updateBankOfficeFreeExecutive(values);

      // Mostrar mensaje de éxito
      message.success('Ejecutivo libre agregado exitosamente');

      // Limpiar campos de formulario
      freeExecutiveFormInstance.resetFields();
      setAppOptions([]);

      // Terminar ejecución y ocultar modal
      setOpenModal(false);
    } catch (error) {
      message.error(error.message);
    }

    // Deshabilitar carga del spinner
    setLoading(false);
  };

  /**
   *
   * @param {*} errorInfo
   * Muestra un error en caso de que no se haya podido guardar la información en la BD.
   */
  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  // Renderizar componente
  return (
    <Modal
      onCancel={() => {
        freeExecutiveFormInstance.resetFields();
        setAppOptions([]);
        setOpenModal(null);
      }}
      confirmLoading={loading}
      footer={<div id="bank-modal-footer"></div>}
      visible={openModal}
    >
      <div id="bank-modal-body">
        <h3>Elegir Ejecutivo Libre</h3>
        <Form
          form={freeExecutiveFormInstance}
          className={'modal-form'}
          layout={'vertical'}
          name="link_form"
          onValuesChange={onValuesChange}
          onFinish={(values) => onFinish(values)}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Email del Ejecutivo"
            name="executiveEmail"
            rules={[
              { required: true, message: 'Seleccione el email del ejecutivo libre' },
            ]}
          >
            <Select placeholder="Selecciona">
              {freeExecutives &&
                freeExecutives.docs.map((item) => (
                  <Option key={item.id} value={item.get('email')}>
                    {item.get('email')}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Email del Administrador (Supervisor)"
            name="adminEmail"
            rules={[
              { required: true, message: 'Seleccione el email del administrador' },
            ]}
          >
            <Select placeholder="Selecciona">
              {bankOfficeAdmins &&
                bankOfficeAdmins.docs.map((item) => (
                  <Option key={item.id} value={item.get('email')}>
                    {item.get('email')}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Tipos de Solicitudes"
            name="bankOfficeAppTypes"
            rules={[
              {
                required: true,
                message:
                  'Seleccione al menos un tipo de solicitud que maneje el ejecutivo',
              },
            ]}
          >
            <Checkbox.Group options={appOptions} />
          </Form.Item>
          <Form.Item className={'button-holder'}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Agregar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default NewFreeExecutiveModal;
