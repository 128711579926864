
class applicationLogic {
  constructor(db, fieldValue) {
    this.db = db;
    this.fieldValue = fieldValue;
  }

  application = (uid) => this.db.doc(`applications/${uid}`);

  applications = () => this.db.collection('applications');
}

export default applicationLogic;
