import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import config from './../../config/firebase';
import authenticationLogic from './authentication';
import userLogic from './user';
import articleLogic from './article';
import applicationLogic from './application';
import bankLogic from './bank';
import bankOfficeLogic from './bankOffice';
import interestRateLogic from './interestRate';

/**Lo que esta en la clase Firebase, puede ser accedido en cualquier lugar. Para usarlos hay que hacer 2 cosas
1. Poner a lo mas arriba del arbol <FirebaseContext.Provider value=new Firebase()> (Ya esta)
2. Cuando queramos acceder a algun elemento de la clase firebase usar
<FirebaseContext.Consumer>
  {firebase => {
    return <div>I've access to Firebase and render something.</div>;
  }}
</FirebaseContext.Consumer>
*/
class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */
    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();
    this.storage = app.storage();

    // Lógica de la colección users
    this.userLogic = new userLogic(this.db);

    // Lógica de la colección authentication
    this.authLogic = new authenticationLogic(app, this.auth, this.userLogic);

    // Lógica de la colección articles
    this.articleLogic = new articleLogic(this.db, this.fieldValue);
    this.applicationLogic = new applicationLogic(this.db, this.fieldValue);

    // Lógica de la colección banks
    this.bankLogic = new bankLogic(this.db, this.fieldValue, this.functions);

    // Lógica de la colección bankOffices
    this.bankOfficeLogic = new bankOfficeLogic(
      this.db,
      this.fieldValue,
      this.functions
    );

    // Lógica de la colección interestRate
    this.interestRateLogic = new interestRateLogic(this.db, this.fieldValue);
  }

  // *** Merge Auth and DB User API *** //
  getDownloadURL = (filePath) => {
    const storageRef = this.storage.ref();
    const fileRef = storageRef.child(filePath);

    return fileRef.getDownloadURL().catch((e) => null);
  };
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.userLogic
          .user(authUser.uid)
          .get()
          .then((snapshot) => {
            let dbUser = snapshot.data();

            if (!dbUser) {
              // console.log('No dbUser');
              dbUser = {};
            }

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          })
          .catch((error) => {
            console.log('Error:', error);
            // fallback();
          });
      } else {
        fallback();
      }
    });
}

export default Firebase;
