// Importar librerías
import React, { useContext, useEffect, useState } from 'react';

// Importar context de firebase
import { FirebaseContext } from '../../../context';

// Importar otros componentes
import { ReactComponent as DonwloadIcon } from './../assets/donwload.svg';

/**
 *
 * @param {*} x
 * Agrega la información de la moneda local al valor numérico de entrada.
 */
const numberWithPoints = (x) => {
  if (!x) return 'NA';
  return x.toLocaleString();
};

const TakenApplicationAnswerShowHipotecario = ({
  application,
  currentBankAnswer,
}) => {
  // Obtener información del context de firebase
  const firebase = useContext(FirebaseContext);

  // Definir state local
  const [currentFileAnswer, setCurrentFileAnswer] = useState([]);

  // Definir effect para setear la respuesta del banco en la variable currentFileAnswer
  useEffect(() => {
    const loadBankAnswerFiles = async () => {
      if (currentBankAnswer && currentBankAnswer.status === 'offer') {
        const { answerFiles } = currentBankAnswer.answer;
        const currentFiles = answerFiles.map(firebase.getDownloadURL);
        const aux = await Promise.all(currentFiles.map((p) => p.catch((e) => null)));
        const aux2 = aux.map((d, i) => {
          return {
            uid: d,
            name: answerFiles[i].split(application.id + '/')[1],
            status: 'done',
            url: d,
            old: true,
            oldVoucher: answerFiles[i],
          };
        });

        setCurrentFileAnswer(aux2);
      }
    };
    loadBankAnswerFiles();
  }, [application, currentBankAnswer, firebase.getDownloadURL]);

  /**
   * Abre un archivo en el navegador.
   */
  const openFiles = () => {
    currentFileAnswer.forEach((file) => {
      window.open(file.url);
    });
  };

  // Renderizar componente
  return (
    <div id={'taken-answer'}>
      <div className={'first'}>
        <h2>Datos del Crédito Hipotecario</h2>
        <div className="data">
          <div>
            <span className="title">Banco</span>
            <span>{currentBankAnswer.bankName}</span>
          </div>
          <div>
            <span className="title">Tipo de Propiedad</span>
            <span>{application.get('propiedad')}</span>
          </div>
          <div>
            <span className="title">Condición de la Propiedad</span>
            <span>{application.get('propertyCondition') || '-'}</span>
          </div>
          {application.get('type') === 'hipotecario' && (
            <div>
              <span className="title">Valor de Propiedad</span>
              <span>
                UF{' '}
                {numberWithPoints(
                  application.get('valor') * application.get('valorMoneda')
                )}
              </span>
            </div>
          )}
          <div>
            <span className="title">Dirección</span>
            <span>{application.get('direccion')}</span>
          </div>
        </div>
      </div>
      <div className={'second'}>
        <h2>Dividendo</h2>
        <div className="data">
          <div>
            <span className="title">Tasa</span>
            <span>{currentBankAnswer.answer.tasa}%</span>
          </div>
          <div>
            <span className="title">CAE</span>
            <span>{currentBankAnswer.answer.cae1}%</span>
          </div>
          <div>
            <span className="title">Seguro desgravamen</span>
            <span>
              UF {numberWithPoints(currentBankAnswer.answer.seguroDesgravamen1)}
            </span>
          </div>
          <div>
            <span className="title">Seguro sismo e incendio</span>
            <span>
              UF {numberWithPoints(currentBankAnswer.answer.seguroSismoIncendio1)}
            </span>
          </div>
          <div>
            <span className="title">Dividendo mensual sin seguros UF</span>
            <span>
              UF {numberWithPoints(currentBankAnswer.answer.dividendoMensualUF)}
            </span>
          </div>
          <div>
            <span className="title">Dividendo Total UF</span>
            <span>
              UF {numberWithPoints(currentBankAnswer.answer.dividendoTotalUF1)}
            </span>
          </div>
        </div>
      </div>
      <div className={'files'}>
        <span onClick={openFiles}>
          Descargar Oferta Banco <DonwloadIcon />
        </span>
      </div>
    </div>
  );
};

export default TakenApplicationAnswerShowHipotecario;
