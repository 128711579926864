export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const SIGN_OUT = '/signout';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';

export const USERS_PAGE = '/users';
export const USER_PAGE = '/users/:id';

export const APPLICATIONS_PAGE = '/solicitudes';
export const APPLICATION_PAGE = '/solicitudes/:id';

export const BANKS_PAGE = '/bank';
export const BANK_PAGE = '/bank/:id';
export const EDIT_BANK_PAGE = '/bank/:id/edit';
export const NEW_BANK_PAGE = '/bank/new';

export const BANK_OFFICES_PAGE = '/bankoffice';
export const BANK_OFFICE_PAGE = '/bankoffice/:id';

export const FINANTIAL_EDUCATION = '/educacion';
export const FINANCIAL_EDUCATION_PAGE = '/educacion/:id';
export const ARTICLES_PAGE = '/articles';
export const ARTICLE_PAGE = '/articles/:id';
export const EDIT_ARTICLE_PAGE = '/articles/:id/edit';
export const NEW_ARTICLE_PAGE = '/articles/new';

export const METRICS_PAGE = '/metrics';

export const AVERAGE_INTEREST_RATE_PAGE = '/average-interest-rate';

export const BANKS_PARAMETERIZATION_PAGE = '/banks-parameterization';
