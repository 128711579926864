// Importar librerías
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from 'antd';

// Importar otros componentes
import CustomSider from './Layout/CustomSider';
import CustomHeader from './Layout/CustomHeader';
import SignInPage from './Session/signIn';
import SignOut from './Session/SignOut';
import PasswordForgetPage from './Session/passwordForget';
import HomePage from './Home';
import UsersPage from './Users';
import ArticlesPage from './Articles';
import ApplicationPage from './Applications';
import BanksPage from './Bank';
import BankOfficesPage from './BankOffices';
import FinantialEducationPage from './FinantialEducation';
import MetricsPage from './Metrics';
import AverageInterestRate from './AverageInterestRate';
import BanksParameterization from './BankParameterization';

// Importar rutas
import * as ROUTES from '../constants/routes';

// Importar condicionales y utilidades
import { adminUserCondition } from '../constants/conditions';
import { withAuthentication, withHardAuthorization } from '../utils';

// Importar context de user
import { AuthUserContext } from '../context';

// Importar subcomponente Content
const { Content } = Layout;

/**
 * Renderiza información de las rutas públicas junto a sus componentes.
 */
const PublicLayout = () => (
  <Switch>
    <Route path={ROUTES.SIGN_IN} component={SignInPage} />
    <Route path={ROUTES.SIGN_OUT} component={SignOut} />
    <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
  </Switch>
);

/**
 * Renderiza información de las rutas privadas junto a sus componentes.
 */
const PrivateLayout = () => (
  <Layout>
    <CustomHeader />
    <Content className="content">
      <Switch>
        <Route path={ROUTES.HOME} component={HomePage} />
        <Route path={ROUTES.ARTICLES_PAGE} component={ArticlesPage} />
        <Route
          path={ROUTES.FINANTIAL_EDUCATION}
          component={FinantialEducationPage}
        />
        <Route path={ROUTES.BANKS_PAGE} component={BanksPage} />
        <Route path={ROUTES.METRICS_PAGE} component={MetricsPage} />
        <Route
          path={ROUTES.BANKS_PARAMETERIZATION_PAGE}
          component={BanksParameterization}
        />
        <Route path={ROUTES.BANK_OFFICES_PAGE} component={BankOfficesPage} />
        <Route path={ROUTES.APPLICATIONS_PAGE} component={ApplicationPage} />
        <Route
          path={ROUTES.AVERAGE_INTEREST_RATE_PAGE}
          component={AverageInterestRate}
        />
        <Route path={ROUTES.USERS_PAGE} component={UsersPage} />
      </Switch>
    </Content>
  </Layout>
);

const App = () => {
  // Obtener información del context de user
  const authUser = useContext(AuthUserContext);

  // Renderizar componente
  return (
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
        {/* Se renderea segun la ruta  */}
        <CustomSider />
        <Content className="main-content">
          <Switch>
            <Route exact path={ROUTES.LANDING}>
              {authUser ? (
                <Redirect to={ROUTES.HOME} />
              ) : (
                <Redirect to={ROUTES.SIGN_IN} />
              )}
            </Route>
            <Route path={ROUTES.SIGN_IN} component={PublicLayout} />
            <Route path={ROUTES.SIGN_OUT} component={PublicLayout} />
            <Route path={ROUTES.PASSWORD_FORGET} component={PublicLayout} />
            <Route
              path="/"
              component={withHardAuthorization(adminUserCondition)(PrivateLayout)}
            />
          </Switch>
        </Content>
      </Layout>
    </Router>
  );
};

export default withAuthentication(App);
