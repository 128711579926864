// Importar librerías
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

const AnnualCharts = ({ data }) => {
  // Renderizar componente
  return (
    <BarChart
      data={data}
      height={300}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 50,
      }}
      width={1030}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="month" label={{ value: 'Meses', position: 'bottom' }} />
      <YAxis label={{ value: 'Solicitudes', angle: -90, position: 'insideLeft' }} />
      <Tooltip />
      <Legend wrapperStyle={{ bottom: 0 }} />
      <Bar dataKey="quotes" fill="#e81687" name="Todas" stackId="a" />
      <Bar dataKey="offered" fill="#4292ff" name="Ofertadas" stackId="a" />
      <Bar dataKey="rejected" fill="#febb2c" name="Rechazadas" stackId="a" />
      <Bar dataKey="notOffered" fill="#22d3bd" name="Sin Respuesta" stackId="a" />
    </BarChart>
  );
};

export default AnnualCharts;
