// Importar librerías
import React, { useContext, useState } from 'react';
import { Table, Divider, Tag } from 'antd';
import { useCollection } from 'react-firebase-hooks/firestore';

// Importar context de firebase
import { FirebaseContext } from './../../context';

// Importar otros componentes
import ShowUserModal from './ShowUserModal';

/**
 *
 * @param {*} record
 * Devuelve un rol asociado a un registro de entrada.
 */
const getRoles = (record) => {
  const roleMappings = {
    BANKADMIN: 'Administrador de banco',
    BANKEXECUTIVE: 'Ejecutivo de banco',
    ADMIN: 'Administrador Flit',
    NORMAL: 'Normal',
    '': 'Normal',
  };
  const roles = record.get('roles');
  if (!roles) console.log(record.id);
  const roleList = Object.keys(roles);

  if (roleList.length === 0) return [roleMappings['']];
  else return roleList.map((d) => roleMappings[d]);
};

const UserList = () => {
  // Obtener información del context de firebase
  const firebase = useContext(FirebaseContext);

  // Obtener información de los usuarios de la BD
  const [users, loading] = useCollection(firebase.userLogic.users(), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  // Definir state
  const [openModal, setOpenModal] = useState(null);

  // Definir columnas de la tabla
  const columns = [
    {
      title: 'NOMBRE',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => `${record.get('firstName')} ${record.get('lastName')}`,
      sorter: (a, b) => a.get('lastName').localeCompare(b.get('lastName')),
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      render: (_, record) => record.get('email'),
      sorter: (a, b) => a.get('email').localeCompare(b.get('email')),
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (_, record) => <a onClick={() => setOpenModal(record)}>ver</a>,
    },
    {
      title: 'ROLES',
      dataIndex: 'roles',
      key: 'roles',
      render: (_, record) => {
        const roleList = getRoles(record);

        return (
          <div>
            {roleList.map((d, i) => (
              <Tag key={i} color={'blue'}>
                {d}
              </Tag>
            ))}
          </div>
        );
      },
      sorter: (a, b) => {
        const roleListA = getRoles(a);
        const roleListB = getRoles(b);
        return roleListA[0].localeCompare(roleListB[0]);
      },
      filters: [
        { text: 'Admin Flit', value: 'Administrador Flit' },
        { text: 'Normal', value: 'Normal' },
        { text: 'Ejecutivo de banco', value: 'Ejecutivo de banco' },
        { text: 'Administrador de banco', value: 'Administrador de banco' },
      ],
      onFilter: (value, record) => getRoles(record).includes(value),
    },
  ];

  // Renderizar componente
  return (
    <div className={'users-list'}>
      <div id={'users-head'}>
        <h1>Usuarios de la Plataforma</h1>
      </div>
      <Divider />
      <div id={'user-main'}>
        <ShowUserModal
          openModal={openModal}
          setModal={setOpenModal}
          getRoles={getRoles}
        />
        <Table
          loading={loading}
          columns={columns}
          dataSource={users ? users.docs : []}
          rowKey={(r) => r.email}
        />
      </div>
    </div>
  );
};

export default UserList;
