// Importar librerías
import React, { useContext, useState, useEffect } from 'react';
import { Divider, Spin } from 'antd';
import { useCollection } from 'react-firebase-hooks/firestore';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/es';
import _ from 'lodash';

// Importar context de firebase
import { FirebaseContext } from './../../context';

// Importar utilidades
import toPesos from '../../utils/toPesos';

// Importar otros componentes
import MonthlyCharts from './MonthlyCharts';
import AnnualCharts from './AnnualCharts';

// Definir idioma del moment
moment.locale('es');

const Metrics = () => {
  // Definir state
  const [dollar, setDollar] = useState(null);
  const [generalContent, setGeneralContent] = useState({});
  const [dataChartAnnualApp, setDataChartAnnualApps] = useState([]);
  const [dataChartMonthlyApp, setDataChartMonthlyApps] = useState([]);
  const [uf, setUf] = useState(null);

  // Obtener context de firebase
  const firebase = useContext(FirebaseContext);

  // Obtener información de la BD de todos los usuarios
  const [users, loadingUsers] = useCollection(firebase.userLogic.users(), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  // Obtener información de la BD de todas cotizaciones
  const [apps, loadingApps] = useCollection(
    firebase.applicationLogic.applications().orderBy('createdAt'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  // Definir effect para obtener información valor del uf
  useEffect(() => {
    axios
      .get('https://mindicador.cl/api')
      .then((response) => {
        setUf(response.data.uf.valor);
        setDollar(response.data.dolar.valor);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Definir effect para construir la información a motrar
  useEffect(() => {
    if (users && apps && uf && dollar) {
      // Obtener información de los usuarios normales, los administradores de banco
      // y los ejecutivos de banco
      let normalUsers = 0,
        bankAdminUsers = 0,
        executiveUsers = 0;
      users.docs.forEach((user) => {
        if (
          user.get('roles.ADMIN') === 'ADMIN' ||
          user.get('roles.NORMAL') === 'NORMAL' ||
          user.get('roles.BANKADMIN') === 'BANKADMIN' ||
          user.get('roles.BANKEXECUTIVE') === 'BANKEXECUTIVE'
        ) {
          if (user.get('roles.NORMAL') === 'NORMAL') {
            normalUsers += 1;
          } else if (user.get('roles.BANKADMIN') === 'BANKADMIN') {
            bankAdminUsers += 1;
          } else if (user.get('roles.BANKEXECUTIVE') === 'BANKEXECUTIVE') {
            executiveUsers += 1;
          }
        } else {
          normalUsers += 1;
        }
      });

      // Definir objeto con información relevante
      const metrics = {
        normalUsers,
        bankAdminUsers,
        executiveUsers,
        numberQuotes: apps.docs.length,
        totalQuotes: 0,
        numberMortgageQuotes: apps.docs.filter(
          (item) => item.get('type') === 'hipotecario'
        ).length,
        totalMortgageQuotes: 0,
        numberCustomQuotes: apps.docs.filter(
          (item) => item.get('type') === 'consumo'
        ).length,
        totalCustomQuotes: 0,
      };

      // Obtener data a graficar de las solicitudes
      let appsAnnualData = {},
        appsMonthlyData = {};
      apps.docs.forEach((app) => {
        // Obtener status, fecha de creación y arreglo de respuestas de la solicitud
        const appStatus = app.get('status');
        const appAnswers = app.get('answers');
        const createdDate = app.get('createdAt').toDate();

        // // Definir formatos del mes
        const monthNumberDate = moment(createdDate).format('M-YYYY');
        const monthYearNameDate = moment(createdDate).format('MMMYYYY');
        const monthNameDate = moment(createdDate).format('MMM');

        // // Definir formatos del día
        const dayDate = moment(createdDate).format('D-MMM');
        const dayNumber = Number(moment(createdDate).format('D'));

        // // Definir información base para los meses y según sea el caso
        if (!appsAnnualData[monthNumberDate] || !appsMonthlyData[monthNumberDate]) {
          appsAnnualData[monthNumberDate] = {
            month: monthYearNameDate,
            notOffered: 0,
            offered: 0,
            quotes: 0,
            rejected: 0,
          };
          appsMonthlyData[monthNumberDate] = {};
        }

        // Definir información base en función del día y según sea el caso
        if (!appsMonthlyData[monthNumberDate][dayDate]) {
          appsMonthlyData[monthNumberDate][dayDate] = {
            createdDate,
            day: dayDate,
            dayNumber,
            month: monthNameDate,
            notOffered: 0,
            offered: 0,
            quotes: 0,
            rejected: 0,
          };
        }

        // Contar cada solicitud
        appsAnnualData[monthNumberDate].quotes += 1;
        appsMonthlyData[monthNumberDate][dayDate].quotes += 1;

        // Construir valores para los tipos de solicitudes
        if (appStatus === 'noOffer') {
          if (_.isEmpty(appAnswers)) {
            appsAnnualData[monthNumberDate].notOffered += 1;
            appsMonthlyData[monthNumberDate][dayDate].notOffered += 1;
          } else {
            appsAnnualData[monthNumberDate].rejected += 1;
            appsMonthlyData[monthNumberDate][dayDate].rejected += 1;
          }
        } else {
          if (!_.isEmpty(appAnswers.filter((item) => item.status === 'offer'))) {
            appsAnnualData[monthNumberDate].offered += 1;
            appsMonthlyData[monthNumberDate][dayDate].offered += 1;
          }
        }

        // Obtener información de los totales según el tipo de solicitud que se
        // esté manejando
        if (app.get('type') === 'hipotecario') {
          metrics.totalMortgageQuotes += app.get('credito') * uf * (1 / dollar);
          metrics.totalQuotes += app.get('credito') * uf * (1 / dollar);
        } else {
          metrics.totalCustomQuotes += app.get('credito') * (1 / dollar);
          metrics.totalQuotes += app.get('credito') * (1 / dollar);
        }
      });

      // Definir 2 arreglos para los datos anual y mensual y dos arreglos para almacenar los últimos 12 // meses y 30 días respectivamente
      let annualChartData = [],
        monthlyChartData = [],
        reserveMonthData = [],
        reserveDayData = [];
      for (var i in appsAnnualData) {
        reserveMonthData = [...reserveMonthData, appsAnnualData[i]];

        for (var j in appsMonthlyData[i]) {
          reserveDayData = [...reserveDayData, appsMonthlyData[i][j]];
        }
      }

      // Tomar información de los últimos 12 meses
      while (annualChartData.length < 12 && !_.isEmpty(reserveMonthData)) {
        annualChartData.unshift(reserveMonthData.pop());
      }

      // Tomar información de los últimos 30 días y completar con valores vacíos
      while (monthlyChartData.length < 30 && !_.isEmpty(reserveDayData)) {
        // Agregar primer elemento
        if (_.isEmpty(monthlyChartData)) {
          monthlyChartData.unshift(reserveDayData.pop());
        }

        // Obtener primer y segundo elemento
        const lastItemDay = monthlyChartData[0];
        let currentItemDay = reserveDayData[reserveDayData.length - 1];

        // Agregar elemento vacío según sea el caso
        if (lastItemDay.month === currentItemDay.month) {
          if (lastItemDay.dayNumber - 1 !== currentItemDay.dayNumber) {
            monthlyChartData.unshift({
              createdDate: new Date(lastItemDay.createdDate - 1 * 24 * 3600 * 1000),
              day: (lastItemDay.dayNumber - 1).toString() + '-' + lastItemDay.month,
              dayNumber: lastItemDay.dayNumber - 1,
              month: lastItemDay.month,
              notOffered: 0,
              offered: 0,
              quotes: 0,
              rejected: 0,
            });
          } else {
            monthlyChartData.unshift(reserveDayData.pop());
          }
        } else {
          let fakeDate = new Date(lastItemDay.createdDate - 1 * 24 * 3600 * 1000);
          if (moment(fakeDate).format('D-MMM') !== currentItemDay.day) {
            monthlyChartData.unshift({
              createdDate: fakeDate,
              day: moment(fakeDate).format('D-MMM'),
              dayNumber: Number(moment(fakeDate).format('D')),
              month: moment(fakeDate).format('MMM'),
              notOffered: 0,
              offered: 0,
              quotes: 0,
              rejected: 0,
            });
          } else {
            monthlyChartData.unshift(reserveDayData.pop());
          }
        }
      }

      // Aplicar redondeo a ciertos campos y ajustar valor numérico
      metrics.totalQuotes = toPesos(metrics.totalQuotes);
      metrics.totalMortgageQuotes = toPesos(metrics.totalMortgageQuotes);
      metrics.totalCustomQuotes = toPesos(metrics.totalCustomQuotes);

      // Almacenar en el state
      setGeneralContent(metrics);
      setDataChartAnnualApps(annualChartData);
      setDataChartMonthlyApps(monthlyChartData);
    }
  }, [users, apps, uf, dollar]);

  // Renderizar componente de carga si aún no hay información
  if (loadingUsers || loadingApps)
    return (
      <div className="loading">
        {' '}
        <Spin size="default" />
      </div>
    );

  // Renderizar componente
  return (
    <div className="metrics-container">
      <h1>Usuarios</h1>
      <div className="metrics-content">
        <div className="metrics-boxes">
          <h2>Normales</h2>
          <span>{generalContent.normalUsers}</span>
        </div>
        <div className="metrics-boxes">
          <h2>Administradores de Banco</h2>
          <span>{generalContent.bankAdminUsers}</span>
        </div>
        <div className="metrics-boxes">
          <h2>Ejecutivos de Banco</h2>
          <span>{generalContent.executiveUsers}</span>
        </div>
      </div>
      <Divider />
      <h1>Solicitudes</h1>
      <div className="metrics-content">
        <div className="metrics-boxes">
          <h2>Todas</h2>
          <p>
            Cantidad: <span>{generalContent.numberQuotes}</span>
          </p>
          <p>
            Monto Total: <span>US$ {generalContent.totalQuotes}</span>
          </p>
        </div>
        <div className="metrics-boxes">
          <h2>Consumo</h2>
          <p>
            Cantidad: <span>{generalContent.numberCustomQuotes}</span>
          </p>
          <p>
            Monto Total: <span>US$ {generalContent.totalCustomQuotes}</span>
          </p>
        </div>
        <div className="metrics-boxes">
          <h2>Hipotecarias</h2>
          <p>
            Cantidad: <span>{generalContent.numberMortgageQuotes}</span>
          </p>
          <p>
            Monto Total: <span>US$ {generalContent.totalMortgageQuotes}</span>
          </p>
        </div>
      </div>
      <Divider />
      <h1>
        Gráfica Anual - Solicitudes (Todas, Ofertadas, Rechazadas, Sin Respuesta)
      </h1>
      <AnnualCharts data={dataChartAnnualApp} />
      <Divider />
      <h1>
        Gráfica Últimos 30 Días - Solicitudes (Todas, Ofertadas, Rechazadas, Sin
        Respuesta)
      </h1>
      <MonthlyCharts data={dataChartMonthlyApp} />
    </div>
  );
};

export default Metrics;
