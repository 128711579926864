// Importar librerías
import React, { useContext, useState, useEffect } from 'react';
import { Table, Divider, Tag, Input } from 'antd';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Importar rutas
import * as ROUTES from './../../constants/routes';

// Importar context de firebase
import { FirebaseContext } from './../../context';

// Importar Subcomponente
const { Search } = Input;

/**
 *
 * @param {*} x
 * Agrega la información de la moneda local al valor numérico de entrada.
 */
const numberWithPoints = (x) => {
  return x.toLocaleString();
};

const ApplicationList = () => {
  // Obtener context de firebase
  const firebase = useContext(FirebaseContext);

  // Obtener información de las cotizaciones almacenadas en la BD
  const [applications, loading] = useCollection(
    firebase.applicationLogic.applications(),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  // Definnir state
  const [showApplications, setShowApplications] = useState(null);
  const [searchId, setSearchId] = useState('');

  // Definir columnas de la tabla
  const columns = [
    {
      title: 'CODIGO',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => record.get('idCostQuote') || record.id,
    },
    {
      title: 'USUARIO',
      dataIndex: 'user',
      key: 'user',
      render: (_, record) => {
        const user = record.get('user');
        return <p>{`${user.firstName} ${user.lastName} - ${user.email}`}</p>;
      },
    },
    {
      title: 'MONTO DEL CRÉDITO',
      key: 'credito',
      render: (_, doc) => {
        const type = doc.get('type');
        switch (type) {
          case 'portabilidad-hipotecario':
          case 'hipotecario':
            return `UF ${numberWithPoints(Math.round(doc.get('credito')))}`;
          case 'portabilidad-consumo':
          case 'consumo':
            return `CLP ${numberWithPoints(Math.round(doc.get('credito')))}`;
          case 'automotriz':
            return `$ ${numberWithPoints(Math.round(doc.get('credito')))}`;
          default:
            return <p>-</p>;
        }
      },
      sorter: (a, b) => a.get('credito') - b.get('credito'),
    },
    {
      title: 'TIPO',
      key: 'type',
      render: (_, doc) => {
        const type = doc.get('type');
        switch (type) {
          case 'portabilidad-hipotecario':
            return <Tag color={'green'}>Portabilidad Hipotecario</Tag>;
          case 'hipotecario':
            return <Tag color={'green'}>Hipotecario</Tag>;
          case 'portabilidad-consumo':
            return <Tag color={'blue'}>Portabilidad Consumo</Tag>;
          case 'consumo':
            return <Tag color={'blue'}>Consumo</Tag>;
          case 'automotriz':
            return <Tag color={'orange'}>Automotriz</Tag>;
          default:
            return <Tag color={'red'}>Default</Tag>;
        }
      },
      sorter: (a, b) => (a.get('type') || '').localeCompare(b.get('type') || ''),
      filters: [
        {
          text: 'Portabilidad Hipotecario',
          value: 'portabilidad-hipotecario',
        },
        {
          text: 'Hipotecario',
          value: 'hipotecario',
        },
        {
          text: 'Portabilidad Consumo',
          value: 'portabilidad-consumo',
        },
        {
          text: 'Consumo',
          value: 'consumo',
        },
        {
          text: 'Automotriz',
          value: 'automotriz',
        },
        {
          text: 'Default',
          value: 'default',
        },
      ],
      onFilter: (value, doc) => (doc.get('type') || 'default') === value,
    },
    {
      title: 'FECHA CREACIÓN',
      key: 'date',
      render: (_, doc) =>
        doc.get('createdAt')
          ? doc.data().createdAt.toDate().toISOString().substring(0, 10)
          : 'Loading',
      sorter: (a, b) => {
        if (a.get('createdAt') && b.get('createdAt'))
          return a.get('createdAt').toDate() - b.get('createdAt').toDate();
        return 1;
      },
      defaultSortOrder: 'descend',
    },
    {
      title: 'ESTADO',
      dataIndex: 'state',
      key: 'state',
      render: (_, record) => {
        const status = record.get('status');
        const hasWinner = record.get('winner') ? true : false;
        const hasAnswer = record.get('answers')[0];

        let hasOffer = hasAnswer
          ? record.get('answers').find((d) => d.status === 'offer')
          : false;

        if (!record.get('endDate')) return 'loading';

        const hasTime = record.get('endDate').toDate().getTime() > Date.now();

        const validUntil = moment(record.get('endDate').toDate()).add(2, 'd');
        const hasAnswerOfferTime = moment().isBefore(validUntil);

        // Queda plazo
        if (hasTime) return 'Esperando respuestas';

        // Se acabo el plazo sin respuestas
        if (!hasAnswer) return 'Plazo cerrado sin respuesta';

        // No tiene ofertas
        if (!hasOffer) return 'Plazo cerrado rechazado';

        if (status === 'rejected' || status === 'userRejected')
          // rechazado
          return 'Propuesta rechazada por el usuario';

        // No hay winner aun pero tiene tiempo para aceptar
        if (!hasWinner && hasAnswerOfferTime)
          return 'Esperando confirmación del usuario';

        // rechazado por ejecutivo
        if (status === 'bankRejected') return 'Propuesta rechazada por el ejecutivo';

        if (status === 'accepted') return 'En trámite';

        // Tiene ofertas pero se le acabo el plazo para aceptar/rechazar
        if (!hasAnswerOfferTime) return 'Plazo para aceptar oferta terminado';
      },
    },
    {
      title: 'ACCIONES',
      dataIndex: 'id',
      key: 'ver',
      render: (text, _) => (
        <Link
          to={{
            pathname: `${ROUTES.APPLICATIONS_PAGE}/${text}`,
          }}
          id={'link'}
        >
          Ver
        </Link>
      ),
    },
  ];

  // Definir effect para setear la información de las solicitudes
  useEffect(() => {
    if (applications) {
      setShowApplications(
        applications.docs.filter((d) => {
          const user = d.get('user');
          const searchField =
            d.id + ' ' + user.firstName + ' ' + user.lastName + ' ' + user.email;

          return searchField.toLowerCase().indexOf(searchId.toLowerCase()) !== -1;
        })
      );
    } else {
      setShowApplications([]);
    }
  }, [applications, searchId]);

  // Renderizar componente
  return (
    <div className={'applications-list'}>
      <div id={'applications-head'}>
        <h1>Solicitudes</h1>
      </div>
      <Divider />
      <div id={'application-main'}>
        <Search
          placeholder="input search text"
          onSearch={(value) => console.log(value)}
          style={{ width: 200 }}
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
        />
        <Table
          loading={loading}
          columns={columns}
          dataSource={showApplications}
          rowKey={(r) => r.id}
        />
      </div>
    </div>
  );
};

export default ApplicationList;
