import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization } from '../../utils';
import ApplicationList from './ApplicationList';
import ApplicationItem from './ApplicationItem';
import * as ROUTES from '../../constants/routes';
import { adminUserCondition } from '../../constants/conditions';

const ApplicationPage = () => (
  <div>
    <Switch>
      <Route exact path={ROUTES.APPLICATION_PAGE} component={ApplicationItem} />
      <Route exact path={ROUTES.APPLICATIONS_PAGE} component={ApplicationList} />
    </Switch>
  </div>
);

export default compose(withAuthorization(adminUserCondition))(ApplicationPage);
