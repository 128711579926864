import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization } from '../../utils';

import ArticleList from './ArticleList';
// import NewArticle from './NewArticle';
import ArticleItem from './ArticleItem';

import * as ROUTES from '../../constants/routes';
import { adminUserCondition } from '../../constants/conditions';

const ArticlesPage = () => (
  <div>
    <Switch>
      {/* Carga por defecto la lista */}
      <Route exact path={ROUTES.ARTICLES_PAGE} component={ArticleList} />
      <Route exact path={ROUTES.ARTICLE_PAGE} component={ArticleItem} />
    </Switch>
  </div>
);

export default compose(withAuthorization(adminUserCondition))(ArticlesPage);
