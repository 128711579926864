export const appOptionsBankOffice = [
  { value: 'consumo', label: 'Consumo' },
  { value: 'hipotecario', label: 'Hipotecario' },
  { value: 'automotriz', label: 'Automotriz' },
  { value: 'portabilidad-consumo', label: 'Portabilidad Consumo' },
  { value: 'portabilidad-hipotecario', label: 'Portabilidad Hipotecario' },
];

export const appOptionsAdminExecutive = {
  consumo: { value: 'consumo', label: 'Consumo' },
  hipotecario: { value: 'hipotecario', label: 'Hipotecario' },
  automotriz: { value: 'automotriz', label: 'Automotriz' },
  'portabilidad-consumo': {
    value: 'portabilidad-consumo',
    label: 'Portabilidad Consumo',
  },
  'portabilidad-hipotecario': {
    value: 'portabilidad-hipotecario',
    label: 'Portabilidad Hipotecario',
  },
};
