import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Menu,
  Table,
  Button,
  message,
  Dropdown,
  Divider,
  Tooltip,
  Tag,
} from 'antd';

import NewArticleModal from './NewArticleModal';
import EditArticleModal from './EditArticleModal';
import { withFirebase } from '../../utils';
import * as ROUTES from '../../constants/routes';

import { DownOutlined } from '@ant-design/icons';
import { ReactComponent as DeleteIcon } from './icons/delete-24px.svg';
import { ReactComponent as EditIcon } from './icons/create-24px.svg';

import moment from 'moment';

const DATE_FORMAT = 'HH:mm DD/MM/YYYY';

const menu = (setModal) => {
  return (
    <Menu onClick={(e) => setModal(e.key)} className={'drop-down'}>
      <Menu.Item key="link">Artículo con enlace</Menu.Item>
      <Menu.Item key="full">Artículo de contenido</Menu.Item>
      <Menu.Item key="financialEducation">
        Artículo de educación financiera
      </Menu.Item>
    </Menu>
  );
};

class ArticleList extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'TITULO',
        dataIndex: 'title',
        key: 'title',
        render: (text) => {
          return (
            <Tooltip title={text}>
              <p className="title">{text}</p>
            </Tooltip>
          );
        },
        sorter: (a, b) => a.title.localeCompare(b.title),
      },
      {
        title: 'RESUMEN',
        dataIndex: 'summary',
        key: 'summary',
        render: (text) => {
          return (
            <Tooltip title={text}>
              <p className="summary">{text}</p>
            </Tooltip>
          );
        },
        sorter: (a, b) => a.summary.localeCompare(b.summary),
      },
      {
        title: 'FOTO',
        dataIndex: 'photoUrl',
        key: 'photoUrl',
        render: (text) => {
          return <Avatar src={text} size="default" shape="square" />;
        },
      },
      {
        title: 'FECHA CREACIÓN',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) => {
          if (!text) {
            return <p></p>;
          }
          return <p>{moment(text.toDate()).format(DATE_FORMAT)}</p>;
        },
        sorter: (a, b) => {
          if (!a.createdAt || !b.createdAt) {
            return -1;
          }
          return a.createdAt.toDate() - b.createdAt.toDate();
        },
        defaultSortOrder: 'descend',
      },
      {
        title: 'TIPO',
        dataIndex: 'type',
        key: 'type',
        render: (text) => {
          if (text === 'full') {
            return <Tag color="blue">Entero</Tag>;
          } else if (text === 'link') {
            return <Tag color="green">Link</Tag>;
          } else {
            return <Tag color="red">Educacion Financiera</Tag>;
          }
        },
        sorter: (a, b) => a.type.localeCompare(b.type),
      },
      {
        title: 'LINK',
        dataIndex: 'link',
        key: 'link',
        render: (text, record) =>
          record.link ? (
            <a id="link" href={record.link}>
              Ir
            </a>
          ) : (
            <p></p>
          ),
      },
      {
        title: 'VER',
        key: 'action1',
        render: (text, record) => {
          const { userRef, createdAt, ...rest } = record;

          if (record.type === 'financialEducation') {
            return (
              <div className={'actions'}>
                <Link
                  style={{ marginRight: 16 }}
                  to={{
                    pathname: `${ROUTES.FINANTIAL_EDUCATION}/${record.uid}`,
                    state: { article: rest },
                  }}
                  id={'link'}
                >
                  Ver
                </Link>
              </div>
            );
          }

          return (
            <div className={'actions'}>
              <Link
                style={{ marginRight: 16 }}
                to={{
                  pathname: `${ROUTES.ARTICLES_PAGE}/${record.uid}`,
                  state: { article: rest },
                }}
                id={'link'}
              >
                Ver
              </Link>
            </div>
          );
        },
      },
      {
        title: '',
        key: 'action2',
        render: (text, record) => {
          return (
            <div className={'actions'}>
              <Tooltip title={'Editar esta noticia'}>
                <span onClick={(event) => this.setEditModal({ event, record })}>
                  <EditIcon />
                </span>
              </Tooltip>
              <Tooltip title={'Eliminar esta noticia'}>
                <span
                  onClick={(event) =>
                    this.removeArticle(event, record.uid, record.key, record.type)
                  }
                >
                  <DeleteIcon />
                </span>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    this.state = {
      loading: false,
      loading2: false,
      articles: [],
      financialEducationArticles: [],
      openModal: null,
      openEditModal: null,
    };
  }

  setModal = (articleType) => {
    if (!articleType) {
      this.setState({ openModal: null });
    } else {
      this.setState({ openModal: articleType });
    }
  };

  setEditModal = (article) => {
    if (!article) {
      this.setState({ openEditModal: null });
    } else {
      this.setState({ openEditModal: article });
    }
  };

  setLoading = (newState) => {
    this.setState({ loading: newState });
  };

  removeArticle = (event, uid, key, type) => {
    event.preventDefault();

    if (type === 'link' || type === 'full') {
      this.props.firebase.articleLogic
        .articleDelete(uid)
        .then(() => {
          message.success('Articulo eliminado correctamente');
        })
        .catch((error) => {
          message.error(error.message);
        });
    } else {
      // Articulo de educacion financiera
      this.props.firebase.articleLogic
        .financialEducationDelete(uid)
        .then(() => {
          message.success(
            'Articulo de educacion financiera eliminado correctamente'
          );
        })
        .catch((error) => {
          message.error(error.message);
        });
    }
  };

  componentDidMount() {
    this.unsubscribe = this.props.firebase.articleLogic
      .articles()
      .onSnapshot((snapshot) => {
        let articles = [];
        let counter = 0;
        snapshot.forEach((doc) => {
          let article = { ...doc.data(), uid: doc.id, key: counter++ };
          // article.userRef.onSnapshot((snap) => {
          //   const userData = snap.data();
          //   article.userData = userData;
          //   // console.log(article);
          // });
          articles.push(article);
        });

        this.setState({
          articles,
          loading: false,
        });
      });

    this.unsubscribe2 = this.props.firebase.articleLogic
      .financialEducation()
      .onSnapshot((snapshot) => {
        let financialEducationArticles = [];
        let counter = 0;
        snapshot.forEach((doc) => {
          let financialEducationArticle = {
            ...doc.data(),
            uid: doc.id,
            key: counter++,
          };
          // article.userRef.onSnapshot((snap) => {
          //   const userData = snap.data();
          //   article.userData = userData;
          //   // console.log(article);
          // });
          financialEducationArticles.push(financialEducationArticle);
        });

        this.setState({
          financialEducationArticles,
          loading2: false,
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.unsubscribe2();
  }

  render() {
    const {
      articles,
      loading,
      loading2,
      openModal,
      openEditModal,
      financialEducationArticles,
    } = this.state;

    return (
      <div className="articles-list">
        <div id="articles-head">
          <h1>Artículos creados</h1>
          <Dropdown overlay={() => menu(this.setModal)}>
            <Button type={'primary'}>
              Agregar un nuevo articulo <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        {/* MODALS */}
        <NewArticleModal
          openModal={openModal}
          setModal={this.setModal}
          loading={loading}
          setLoading={this.setLoading}
        />
        <EditArticleModal
          openModal={openEditModal}
          setModal={this.setEditModal}
          loading={loading}
          setLoading={this.setLoading}
        />
        <Divider />
        <div id="articles-main-table">
          <Table
            loading={loading || loading2}
            size={'middle'}
            columns={this.columns}
            pagination={true}
            dataSource={[...articles, ...financialEducationArticles]}
          />
        </div>
      </div>
    );
  }
}

export default withFirebase(ArticleList);
