// Importar librerías
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

// Importar utilidades
import { withAuthorization } from '../../utils';

// Importar otros componentes
import BankList from './BankList';
import BankItem from './BankItem';

// Importar rutas y condicionales
import * as ROUTES from '../../constants/routes';
import { adminUserCondition } from '../../constants/conditions';

const BanksPage = () => (
  <div>
    <Switch>
      <Route exact path={ROUTES.BANK_PAGE} component={BankItem} />
      <Route exact path={ROUTES.BANKS_PAGE} component={BankList} />
    </Switch>
  </div>
);

export default compose(withAuthorization(adminUserCondition))(BanksPage);
