import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization } from '../../utils';
import UserList from './UserList';
import UserItem from './UserItem';
import * as ROUTES from '../../constants/routes';
import { adminUserCondition } from '../../constants/conditions';

const UsersPage = () => (
  <div>
    <Switch>
      <Route exact path={ROUTES.USER_PAGE} component={UserItem} />
      <Route exact path={ROUTES.USERS_PAGE} component={UserList} />
    </Switch>
  </div>
);

export default compose(withAuthorization(adminUserCondition))(UsersPage);
