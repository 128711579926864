// Importar librerías
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Spin, Tabs, Tag } from 'antd';
import { useDocument } from 'react-firebase-hooks/firestore';
import moment from 'moment';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar utilidades
import { withFirebase, withAuthUser } from '../../utils';

// Importar otros componentes
import { ReactComponent as BackIcon } from './assets/back.svg';
import TakenApplicationAnswerShowIndex from './Answer/TakenApplicationAnswerShowIndex';
import BestAnswerShowIndex from './Answer/BestAnswerShowIndex';
import ApplicationDataIndex from './ApplicationData';

// Importar subcomponente TabPane
const { TabPane } = Tabs;

// Definir formato de fecha
const DATE_FORMAT = 'HH:mm DD/MM/YYYY';

// Definir objeto con los tipos de productos que hay
const products = {
  consumo: 'CRÉDITO DE CONSUMO',
  hipotecario: 'CRÉDITO HIPOTECARIO',
  'portabilidad-consumo': 'PORTABILIDAD CRÉDITO DE CONSUMO',
  'portabilidad-hipotecario': 'PORTABILIDAD CRÉDITO HIPOTECARIO',
};

const ApplicationItem = ({ authUser, firebase, ...props }) => {
  // Obtener id de la aplicación
  const applicationId = props.match.params.id;

  // Obtener información de la cotización almacenada en la BD
  const [applicationDoc, loading, error] = useDocument(
    firebase.applicationLogic.application(applicationId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  // Definir state
  const [applicationLoaded, setApplicationLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState('application');

  // Definir booleano para mostrar la información de la solicitud
  const showApplication = true;

  // Definir effect para setear la información de la solicitud según sea el caso
  useEffect(() => {
    if (error) {
      setApplicationLoaded(false);
    }
    if (loading) {
      setApplicationLoaded(false);
    } else {
      if (applicationDoc.exists) {
        setApplicationLoaded(true);
      } else {
        setApplicationLoaded(false);
      }
    }
  }, [applicationDoc, loading, error]);

  // Definir effect para redireccionar en caso de que no exista información de la solicitud
  useEffect(() => {
    if (!showApplication) {
      props.history.push(ROUTES.HOME);
    }
  });

  // Definir estado de la solicitud junto con el mensaje a mostrar
  let applicationStatusShow = '';
  let rejected = false;
  if (applicationLoaded) {
    const status = applicationDoc.get('status');
    if (status === 'rejected' || status === 'userRejected') {
      rejected = true;
    }
    const hasWinner = applicationDoc.get('winner') ? true : false;
    const hasAnswer = applicationDoc.get('answers')[0];

    let hasOffer = hasAnswer
      ? applicationDoc.get('answers').find((d) => d.status === 'offer')
      : false;

    const hasTime = applicationDoc.get('endDate').toDate().getTime() > Date.now();
    const validUntil = moment(applicationDoc.get('endDate').toDate()).add(2, 'd');
    const hasAnswerOfferTime = moment().isBefore(validUntil);

    // Queda plazo
    if (hasTime) applicationStatusShow = 'Esperando respuestas';
    // Se acabo el plazo sin respuestas
    else if (!hasAnswer) applicationStatusShow = 'Plazo cerrado sin respuesta';
    // No tiene ofertas
    else if (!hasOffer) applicationStatusShow = 'Plazo cerrado rechazado';
    else if (status === 'rejected' || status === 'userRejected') {
      // rechazado
      applicationStatusShow = 'Propuesta rechazada por el usuario';
    }

    // No hay winner aun pero tiene tiempo para aceptar
    else if (!hasWinner && hasAnswerOfferTime)
      applicationStatusShow = 'Esperando confirmación del usuario';
    // rechazado por ejecutivo
    else if (status === 'bankRejected') {
      applicationStatusShow = 'Propuesta rechazada por el ejecutivo';
    } else if (status === 'accepted') applicationStatusShow = 'En trámite';
    // Tiene ofertas pero se le acabo el plazo para aceptar/rechazar
    else if (!hasAnswerOfferTime)
      applicationStatusShow = 'Plazo para aceptar oferta terminado';
  }

  // Obtener valores adicionales. TODO: Validar bien esta parte
  const application = applicationLoaded ? applicationDoc.data() : null;
  const applicationType = applicationLoaded ? applicationDoc.get('type') : null;
  let hasWinner;

  if (applicationLoaded) {
    const winner = applicationDoc.get('winner');
    if (winner && winner.status === 'offer') {
      hasWinner = true;
    } else {
      hasWinner = false;
    }
  } else {
    hasWinner = false;
  }

  // Renderizar componente
  return (
    <Spin spinning={loading}>
      <div>
        {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <span>Document: Loading...</span>}
        {applicationLoaded && showApplication && (
          <div className="application-show">
            <div className="application-header">
              <div className="application-type">{products[applicationType]}</div>
              <div className="main-info">
                <div className="title">
                  <span className="name">{applicationDoc.get('nombre')}</span>
                  <span className="userName">
                    {`${applicationDoc.get('user').firstName} ${
                      applicationDoc.get('user').lastName
                    }`}
                    <span className={'location'}>{applicationDoc.get('rut')}</span>
                    <span className={'location'}>
                      {applicationDoc.get('region')} {applicationDoc.get('comuna')}
                    </span>
                  </span>
                  <span className="createdAt">
                    {moment(applicationDoc.get('createdAt').toDate()).format(
                      DATE_FORMAT
                    )}{' '}
                    hrs
                  </span>
                </div>
                <div className="extra">
                  <div className="back">
                    <Link to={ROUTES.APPLICATIONS_PAGE}>
                      <BackIcon /> Volver a lista
                    </Link>
                  </div>
                </div>
              </div>
              <div className="labels">
                <Tag color={'#E3D7FE'}>{applicationStatusShow}</Tag>
                <Tag className="bold" color={'#05035811'}>
                  {application.idCostQuote || applicationDoc.id}
                </Tag>
              </div>
            </div>
            <Tabs activeKey={activeTab} onTabClick={setActiveTab}>
              <TabPane tab={'Solicitud'} key="application">
                <ApplicationDataIndex
                  application={application}
                  applicationType={applicationType}
                />
              </TabPane>
              <TabPane tab={'Mejor oferta'} key="bestAnswer">
                <BestAnswerShowIndex
                  application={applicationDoc}
                  applicationType={applicationType}
                />
              </TabPane>
              <TabPane tab={'Oferta aceptada'} key="answer" disabled={!hasWinner}>
                <TakenApplicationAnswerShowIndex
                  application={applicationDoc}
                  applicationType={applicationType}
                  rejected={rejected}
                />
              </TabPane>
            </Tabs>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default withAuthUser(withFirebase(ApplicationItem));
