import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { withFirebase } from './../../utils';
import * as ROUTES from './../../constants/routes';

const PasswordForgetPage = () => (
  <div className={'password-forget'}>
    <h1>¿Olvidaste tu contraseña?</h1>
    <PasswordForgetForm />
    <Link to={ROUTES.SIGN_IN}>Volver</Link>
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (values) => {
    const { email } = values;

    this.props.firebase.authLogic
      .doPasswordReset(email)
      .then(() => {
        message.success('Se ha enviado un email para reestablecer su contraseña');
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.SIGN_IN);
      })
      .catch((error) => {
        this.setState({ error });
        message.error(error.message);
      });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <Form
        name="forgot_password"
        className="forgot-password-form"
        initialValues={{
          remember: true,
        }}
        layout="vertical"
        onFinish={this.onSubmit}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your Email!',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Recuperar contraseña
        </Button>
      </Form>
    );
  }
}

const PasswordForgetLink = () => (
  <p className="login-form-forgot">
    <Link to={ROUTES.PASSWORD_FORGET}>¿Olvidaste tu contraseña?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withRouter(withFirebase(PasswordForgetFormBase));

export { PasswordForgetForm, PasswordForgetLink };
