// *** User API ***

class userLogic {
  constructor(db, userLogic) {
    this.db = db;
  }

  user = (uid) => this.db.doc(`users/${uid}`);

  newUser = (uid, data) => {
    const { firstName, lastName, email, roles } = data;
    return this.user(uid).set(
      {
        firstName,
        lastName,
        email,
        roles,
        applications: [],
      },
      { merge: true }
    );
  };

  users = () => this.db.collection('users');
}

export default userLogic;
