// *** Article API ***

class articleLogic {
  constructor(db, fieldValue) {
    this.db = db;
    this.fieldValue = fieldValue;
  }

  article = (uid) => this.db.doc(`articles/${uid}`);

  articles = () => this.db.collection('articles');

  financialEducation = () => this.db.collection('financialEducation');

  articlesFullLoad = () => {};

  articleEdit = (oldArticle, newValues) => {
    const { type } = oldArticle;
    switch (type) {
      case 'link': {
        const oldValues = {
          title: oldArticle.title,
          photoUrl: oldArticle.photoUrl,
          summary: oldArticle.summary,
          link: oldArticle.link,
        };
        if (JSON.stringify(oldValues) === JSON.stringify(newValues)) {
          return Promise.reject(new Error('No changes'));
        }
        return this.article(oldArticle.uid).update(newValues);
      }
      case 'full': {
        const oldValues = {
          title: oldArticle.title,
          photoUrl: oldArticle.photoUrl,
          summary: oldArticle.summary,
          article_content: oldArticle.article_content,
        };
        if (JSON.stringify(oldValues) === JSON.stringify(newValues)) {
          return Promise.reject(new Error('No changes'));
        }
        return this.article(oldArticle.uid).update({
          ...newValues,
          article_content: newValues.article_content.replace(/\n/gm, '_n'),
        });
      }
      case 'financialEducation': {
        const oldValues = {
          title: oldArticle.title,
          photoUrl: oldArticle.photoUrl,
          summary: oldArticle.summary,
          content: oldArticle.content,
        };
        if (JSON.stringify(oldValues) === JSON.stringify(newValues)) {
          return Promise.reject(new Error('No changes'));
        }
        return this.financialEducation()
          .doc(oldArticle.uid)
          .update({
            ...newValues,
            content: newValues.content.replace(/\n/gm, '_n'),
          });
      }

      default:
        break;
    }
  };

  newArticle = (type, content, userId) => {
    switch (type) {
      case 'link': {
        const { title, photoUrl, summary, link } = content;
        return this.articles().add({
          type,
          title,
          photoUrl,
          summary,
          link,
          userRef: this.db.doc(`users/${userId}`),
          userId,
          createdAt: this.fieldValue.serverTimestamp(),
        });
      }
      case 'full': {
        const { title, photoUrl, summary, article_content } = content;
        return this.articles().add({
          type,
          title,
          photoUrl,
          summary,
          article_content: article_content.replace(/\n/gm, '_n'),
          userRef: this.db.doc(`users/${userId}`),
          userId,
          createdAt: this.fieldValue.serverTimestamp(),
        });
      }
      case 'financialEducation': {
        const { title, summary, photoUrl, urlShow } = content;

        const newContent = content.content.replace(/\n/gm, '_n');

        return this.financialEducation()
          .doc(urlShow)
          .set({
            title,
            type: 'financialEducation',
            photoUrl,
            summary,
            content: newContent,
            userRef: this.db.doc(`users/${userId}`),
            userId,
            createdAt: this.fieldValue.serverTimestamp(),
          });
      }
      default:
        break;
    }
  };

  articleDelete = (uid) => this.article(uid).delete();
  financialEducationDelete = (uid) =>
    this.financialEducation()
      .doc(uid)
      .delete();
}

export default articleLogic;
