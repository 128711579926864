import React, { useContext } from 'react';
import { message, Input, Form, Button, Modal } from 'antd';

import { FirebaseContext } from './../../context';

const FinancialInfoForm = ({ onFinish, onFinishFailed, loading, initialValues }) => (
  <Form
    name="financial_form"
    className={'modal-form'}
    layout={'vertical'}
    initialValues={{
      ...initialValues,
      content: initialValues.content.replace(/_n/gm, '\n'),
    }}
    onFinish={(values) => onFinish(values)}
    onFinishFailed={onFinishFailed}
  >
    <Form.Item
      label="Título"
      name="title"
      rules={[{ required: true, message: 'Agregar un titulo' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Resumen"
      name="summary"
      rules={[{ required: true, message: 'Agregar un resumen' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Enlace Foto a mostrar junto al resumen"
      name="photoUrl"
      rules={[{ required: true, message: 'Agregar una foto a la noticia' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Contenido (soporta markdown)"
      name="content"
      rules={[{ required: true, message: 'Agregar el contenido' }]}
    >
      <Input.TextArea />
    </Form.Item>

    <Form.Item className={'button-holder'}>
      <Button type="primary" htmlType="submit" loading={loading}>
        Mandar cambios
      </Button>
    </Form.Item>
  </Form>
);

const LinkForm = ({ onFinish, onFinishFailed, loading, initialValues }) => (
  <Form
    name="link_form"
    className={'modal-form'}
    layout={'vertical'}
    initialValues={initialValues}
    onFinish={(values) => onFinish(values)}
    onFinishFailed={onFinishFailed}
  >
    <Form.Item
      label="Título"
      name="title"
      rules={[{ required: true, message: 'Agregar un titulo' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Resumen"
      name="summary"
      rules={[{ required: true, message: 'Agregar un resumen' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Enlace Foto a mostrar junto al resumen"
      name="photoUrl"
      rules={[{ required: true, message: 'Agregar una foto a la noticia' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Enlace externo"
      name="link"
      rules={[{ required: true, message: 'Agregar un link a la noticia' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item className={'button-holder'}>
      <Button type="primary" htmlType="submit" loading={loading}>
        Mandar cambios
      </Button>
    </Form.Item>
  </Form>
);

const ContentForm = ({ onFinish, onFinishFailed, loading, initialValues }) => (
  <Form
    className={'modal-form'}
    layout={'vertical'}
    name="link_form"
    initialValues={{
      ...initialValues,
      article_content: initialValues.article_content.replace(/_n/gm, '\n'),
    }}
    onFinish={(values) => onFinish(values)}
    onFinishFailed={onFinishFailed}
  >
    <Form.Item
      label="Título"
      name="title"
      rules={[{ required: true, message: 'Agregar un titulo' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Enlace Foto a mostrar junto al resumen"
      name="photoUrl"
      rules={[{ required: true, message: 'Agregar una foto a la noticia' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Resumen"
      name="summary"
      rules={[{ required: true, message: 'Agregar un resumen' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Contenido"
      name="article_content"
      rules={[{ required: true, message: 'Agregar contenido a la noticia' }]}
    >
      <Input.TextArea />
    </Form.Item>

    <Form.Item className={'button-holder'}>
      <Button type="primary" htmlType="submit" loading={loading}>
        Mandar cambios
      </Button>
    </Form.Item>
  </Form>
);

const EditArticleModal = ({ openModal, setModal, loading, setLoading }) => {
  //   const [loading, setLoading] = useState(false);
  const firebase = useContext(FirebaseContext);
  const record = openModal ? openModal.record : {};

  const onFinish = (values) => {
    setLoading(true);

    firebase.articleLogic
      .articleEdit(record, values)
      .then(() => {
        message.success('Editado correctamente');
        if (record.type === 'link') {
          record.link = values.link;
        } else {
          record.content = values.content;
        }
        record.title = values.title;
        record.summary = values.summary;
        setLoading(false);
        setModal(null);
      })
      .catch((error) => {
        message.error(error.message);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    message.error('Error');
  };

  const renderFormModal = (modalType) => {
    switch (modalType) {
      case 'link':
        return (
          <div id="article-modal-body">
            <h3>Editar artículo con enlace </h3>
            <LinkForm
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              loading={loading}
              initialValues={record}
            />
          </div>
        );
      case 'full':
        return (
          <div id="article-modal-body">
            <h3>Editar artículo con contenido</h3>
            <ContentForm
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              loading={loading}
              initialValues={record}
            />
          </div>
        );

      case 'financialEducation':
        return (
          <div id="article-modal-body">
            <h3>Editar artículo de educacion financiera</h3>
            <FinancialInfoForm
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              loading={loading}
              initialValues={record}
            />
          </div>
        );

      default:
        return;
    }
  };

  const articleType = openModal ? openModal.record.type : '';

  if (!articleType) {
    return (
      <Modal
        onCancel={() => {
          setModal(null);
        }}
        confirmLoading={loading}
        footer={<div id="assign-modal-footer"></div>}
        visible={openModal}
      ></Modal>
    );
  }

  return (
    <Modal
      onCancel={() => {
        setModal(null);
      }}
      confirmLoading={loading}
      footer={<div id="assign-modal-footer"></div>}
      visible={openModal}
    >
      {renderFormModal(articleType)}
    </Modal>
  );
};

export default EditArticleModal;
