// Importar librerías
import React, { useState, useContext } from 'react';
import { message, Input, Form, Button, Modal } from 'antd';

// Importar context de firebase
import { FirebaseContext } from './../../context';

/**
 *
 * @param {*} param0
 * Renderiza un formulario para agregar información del banco.
 */
const BankForm = ({ onFinish, onFinishFailed, loading, bankFormInstance }) => (
  <Form
    className={'modal-form'}
    layout={'vertical'}
    form={bankFormInstance}
    name="link_form"
    onFinish={(values) => onFinish(values)}
    onFinishFailed={onFinishFailed}
  >
    <Form.Item
      label="Nombre del Banco"
      name="bankName"
      rules={[{ required: true, message: 'Agregar un nombre para el banco' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item className={'button-holder'}>
      <Button type="primary" htmlType="submit" loading={loading}>
        Crear
      </Button>
    </Form.Item>
  </Form>
);

const NewBankModal = ({ openModal, setModal }) => {
  // Obtener información del context
  const firebase = useContext(FirebaseContext);

  // Definir state
  const [loading, setLoading] = useState(false);

  // Crear instancias de useForm
  const [bankFormInstance] = Form.useForm();

  /**
   *
   * @param {*} values
   * Almacena la información del nuevo banco que se está creando en la BD.
   */
  const onFinish = async (values) => {
    // Habilitar spinner de cargando
    setLoading(true);

    // Intentar almacenar en la BD
    try {
      await firebase.bankLogic.newBank(values);

      // Colocar mensaje de éxito
      message.success('Banco creado correctamente');

      // Limpiar formulario
      bankFormInstance.resetFields();

      // Setear valores de los estados Loading y Modal
      setModal(null);
    } catch (error) {
      message.error(error.message);
    }

    // Deshabilitar spinner de cargando
    setLoading(false);
  };

  /**
   *
   * @param {*} errorInfo
   * Muestra un error en caso de que no se haya podido guardar la información en la BD.
   */
  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
    message.error('Error');
  };

  // Renderizar componente
  return (
    <Modal
      onCancel={() => {
        setModal(null);
      }}
      confirmLoading={loading}
      footer={<div id="bank-modal-footer"></div>}
      visible={openModal}
    >
      <div id="bank-modal-body">
        <h3>Agregar Nuevo Banco</h3>
        <BankForm
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          loading={loading}
          bankFormInstance={bankFormInstance}
        />
      </div>
    </Modal>
  );
};

export default NewBankModal;
