// Importar librerías
import React, { useState, useContext, useEffect } from 'react';
import { message, Select, Form, Button, Modal } from 'antd';
import { useCollection } from 'react-firebase-hooks/firestore';

// Importar context de firebase
import { FirebaseContext } from '../../context';

// Importar subcomponente Option
const { Option } = Select;

const DeleteAdminModal = ({ openModal, setOpenModal, bankOffices }) => {
  // Obtener información del context de firebase
  const firebase = useContext(FirebaseContext);

  // Obtener información de los bancos
  const [banks] = useCollection(firebase.bankLogic.banks(), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  // Definir state
  const [loading, setLoading] = useState(false);

  const [banksAvailable, setBanksAvailable] = useState(null);
  const [bankOfficesAvailable, setBankOfficesAvailable] = useState(null);
  const [adminsOfficesAvailable, setAdminsOfficesAvailable] = useState(null);
  const [selectedBankId, setSelectedBankId] = useState(null);

  // Crear instancias de useForm
  const [bankOfficeAdminFormInstance] = Form.useForm();

  // Definir effect para setear sólo la información de los bancos que tengan
  // sucursales
  useEffect(() => {
    if (banks) {
      // Filtrar los bancos que tengas sucursales y setear la información
      const banksFiltered = banks.docs.filter(
        (item) => item.get('bankOffices') && item.get('bankOffices').length >= 1
      );
      setBanksAvailable(banksFiltered);
    }
  }, [banks]);

  /**
   *
   * @param {*} changedValue
   * @param {*} allValues
   * Detecta los cambios que ocurran con cada campo del formulario.
   */
  const onValuesChange = (changedValue, allValues) => {
    // Obtener información del campo que se está cambiando
    const field = Object.keys(changedValue)[0];

    // Reinicializar selector de sucursales cuando se haga un cambio en el banco
    if (field === 'bankName') {
      // Obtener nombre del banco
      const { bankName } = allValues;

      // Obtener información de las sucursales
      const officesAvailable = bankOffices.docs.filter(
        (item) => item.get('bankName') === bankName
      );

      // Setear información
      setBankOfficesAvailable(officesAvailable);

      // Inicializar selector de sucursales
      bankOfficeAdminFormInstance.setFieldsValue({ bankOfficeName: null });
    }

    // Reinicializar selector de correos de administradores cuando haya un cambio de sucursal
    if (field === 'bankOfficeId') {
      // Obtener nombre de la sucursal
      const { bankOfficeId } = allValues;

      const officeSelected = bankOffices.docs.filter(
        (item) => item.id === bankOfficeId
      )[0];

      // Setear información
      setAdminsOfficesAvailable(officeSelected.get('bankOfficeAdmins'));

      // Inicializar selector de administradores
      bankOfficeAdminFormInstance.setFieldsValue({ email: null });
    }
  };

  /**
   *
   * @param {*} values
   * Almacena la información del nuevo administrador que se está creando en la BD.
   */
  const onFinish = async (values) => {
    // Habilitar carga del spinner
    setLoading(true);

    // Agregar información adicional a los valores de entrada
    values = {
      ...values,
      bankId: selectedBankId,
    };

    // Intentar eliminar en la BD
    try {
      await firebase.bankOfficeLogic.deleteBankOfficeAdmin(values);

      // Mostrar mensaje de éxito
      message.success('Administrador eliminado exitosamente');

      // Limpiar campos de formulario
      bankOfficeAdminFormInstance.resetFields();

      // Terminar ejecución y ocultar modal
      setOpenModal(false);
    } catch (error) {
      message.error(error.message);
    }

    // Deshabilitar carga del spinner
    setLoading(false);
  };

  /**
   *
   * @param {*} errorInfo
   * Muestra un error en caso de que no se haya podido guardar la información en la BD.
   */
  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  // Renderizar componente
  return (
    <Modal
      onCancel={() => {
        bankOfficeAdminFormInstance.resetFields();
        setOpenModal(null);
      }}
      confirmLoading={loading}
      footer={<div id="bank-modal-footer"></div>}
      visible={openModal}
    >
      <div id="bank-modal-body">
        <h3>Eliminar Administrador</h3>
        <Form
          form={bankOfficeAdminFormInstance}
          className={'modal-form'}
          layout={'vertical'}
          name="link_form"
          onValuesChange={onValuesChange}
          onFinish={(values) => onFinish(values)}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Nombre del Banco"
            name="bankName"
            rules={[{ required: true, message: 'Seleccione el nombre del banco' }]}
          >
            <Select
              placeholder="Selecciona"
              onSelect={(_, selectedBank) => setSelectedBankId(selectedBank.key)}
            >
              {banksAvailable &&
                banksAvailable.map((item) => (
                  <Option key={item.id} value={item.get('bankName')}>
                    {item.get('bankName')}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Nombre de la Sucursal"
            name="bankOfficeId"
            rules={[
              { required: true, message: 'Seleccione el nombre de la sucursal' },
            ]}
          >
            <Select placeholder="Selecciona">
              {bankOfficesAvailable &&
                bankOfficesAvailable.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.get('bankOfficeName')}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Email del Administrador"
            name="email"
            rules={[
              { required: true, message: 'Seleccione el email del administrador' },
            ]}
          >
            <Select placeholder="Selecciona">
              {adminsOfficesAvailable &&
                adminsOfficesAvailable.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item className={'button-holder'}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Eliminar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default DeleteAdminModal;
