// Importar librerías
import React, { useState, useEffect, useContext } from 'react';
import { message, Button, Modal, Form, Input } from 'antd';

// Importar context de firebase
import { FirebaseContext } from '../../context';

const EditBankModal = ({ openModal, setModal, currentBank }) => {
  // Obtener información del context de firebase
  const firebase = useContext(FirebaseContext);

  // Definir state
  const [loading, setLoading] = useState(false);

  // Crear instancias de useForm
  const [bankFormInstance] = Form.useForm();

  // Definir effect para setear valores iniciales del formulario
  useEffect(() => {
    if (currentBank && openModal) {
      bankFormInstance.setFieldsValue({
        bankName: currentBank.get('bankName'),
      });
    }
  }, [currentBank, bankFormInstance, openModal]);

  /**
   *
   * @param {*} values
   * Almacena la información del banco que se está editando en la BD.
   */
  const onFinish = async (values) => {
    // Habilitar spinner de carga
    setLoading(true);

    // Agregar información adicional a los valores de entrada
    values = {
      ...values,
      bankId: currentBank.id,
      bankOldName: currentBank.get('bankName'),
    };

    // Almacenar en la Base de Datos
    try {
      await firebase.bankLogic.bankUpdate(values);

      // Mostrar mensaje de éxito
      message.success('Información editada exitosamente');

      // Limpiar formulario
      bankFormInstance.resetFields();

      // Setear valor para el state Modal
      setModal(false);
    } catch (error) {
      message.error(error.message);
    }

    // Deshabilitar carga del spinner
    setLoading(false);
  };

  /**
   *
   * @param {*} errorInfo
   * Muestra un error en caso de que no se haya podido guardar la información en la BD.
   */
  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  // Renderizar componente
  return (
    <Modal
      onCancel={() => {
        setModal(null);
      }}
      confirmLoading={loading}
      footer={<div id="bank-modal-footer"></div>}
      visible={openModal}
    >
      <div id="bank-modal-body">
        <h3>Editar Información Banco</h3>
        <Form
          form={bankFormInstance}
          className={'modal-form'}
          layout={'vertical'}
          name="link_form"
          onFinish={(values) => onFinish(values)}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Nombre del Banco"
            name="bankName"
            rules={[{ required: true, message: 'Agregue el nombre del banco' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item className={'button-holder'}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Actualizar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditBankModal;
