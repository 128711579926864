// Importar librerías
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';

// Importar context de firebase
import { FirebaseContext } from '../../../context';

// Importar otros componentes
import { ReactComponent as DonwloadIcon } from './../assets/donwload.svg';

// Definir formato de fecha
const DATE_FORMAT = 'DD/MM/YYYY';

/**
 *
 * @param {*} x
 * Agrega la información de la moneda local al valor numérico de entrada.
 */
const numberWithPoints = (x) => {
  return x.toLocaleString();
};

const TakenApplicationAnswerShowConsumo = ({ application, currentBankAnswer }) => {
  // Obtener información del context de firebase
  const firebase = useContext(FirebaseContext);

  // Definir state
  const [currentFileAnswer, setCurrentFileAnswer] = useState([]);

  // Definir effect para setear la información del banco en la variable currentFileAnswer
  useEffect(() => {
    const loadBankAnswerFiles = async () => {
      if (currentBankAnswer && currentBankAnswer.status === 'offer') {
        const { answerFiles } = currentBankAnswer.answer;
        const currentFiles = answerFiles.map(firebase.getDownloadURL);
        const aux = await Promise.all(currentFiles.map((p) => p.catch((e) => null)));
        const aux2 = aux.map((d, i) => {
          return {
            uid: d,
            name: answerFiles[i].split(application.id + '/')[1],
            status: 'done',
            url: d,
            old: true,
            oldVoucher: answerFiles[i],
          };
        });

        setCurrentFileAnswer(aux2);
      }
    };
    loadBankAnswerFiles();
  }, [application, currentBankAnswer, firebase.getDownloadURL]);

  /**
   * Abre un archivo en el navegador.
   */
  const openFiles = () => {
    currentFileAnswer.forEach((file) => {
      window.open(file.url);
    });
  };

  // Renderizar componente
  return (
    <div id={'taken-answer'}>
      <div className={'first'}>
        <h2>Datos del Crédito de Consumo</h2>
        <div className="data">
          <div>
            <span className="title">Banco</span>
            <span>{currentBankAnswer.bankName}</span>
          </div>
          <div>
            <span className="title">Monto del Crédito</span>
            <span>${numberWithPoints(application.get('credito'))}</span>
          </div>
          <div>
            <span className="title">Cuotas</span>
            <span>{application.get('plazo')}</span>
          </div>
          <div>
            {application.get('fechaPrimerPago') ? (
              <>
                <span className="title">Fecha Primera Cuota</span>
                <span>
                  {moment(application.get('fechaPrimerPago').toDate()).format(
                    DATE_FORMAT
                  )}
                </span>
              </>
            ) : (
              <>
                <span className="title">Crédito Bullet</span>
                <span>Un Solo Pago Final</span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={'second'}>
        <h2>Detalle del Crédito</h2>
        <div className="data">
          <div>
            <span className="title">Tasa Mensual</span>
            <span>{currentBankAnswer.answer.tasaMensual}%</span>
          </div>
          <div>
            <span className="title">CAE Anual</span>
            <span>{currentBankAnswer.answer.cae1}%</span>
          </div>
          <div>
            <span className="title">Cuota Mensual</span>
            <span>$ {numberWithPoints(currentBankAnswer.answer.cuotaMensual1)}</span>
          </div>
          <div>
            <span className="title">Costo Total Crédito</span>
            <span>$ {numberWithPoints(currentBankAnswer.answer.costoTotal1)}</span>
          </div>
        </div>
      </div>
      <div className={'files'}>
        <span onClick={openFiles}>
          Descargar Oferta Banco <DonwloadIcon />
        </span>
      </div>
    </div>
  );
};

export default TakenApplicationAnswerShowConsumo;
