// Importar librerías
import React, { useState, useEffect, useContext } from 'react';
import { Spin, Button, Divider, Table, Tag } from 'antd';
import { useLocation } from 'react-router-dom';
import { useCollection } from 'react-firebase-hooks/firestore';
import { ArrowLeftOutlined } from '@ant-design/icons';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar context de firebase
import { FirebaseContext } from './../../context';

/**
 *
 * @param {*} record
 * Devuelve un rol asociado a un registro de entrada.
 */
const getRoles = (record) => {
  const roleMappings = {
    BANKADMIN: 'Administrador',
    BANKEXECUTIVE: 'Ejecutivo',
  };
  const roles = record.get('roles');
  if (!roles) console.log(record.id);
  const roleList = Object.keys(roles);

  if (roleList.length === 0) return [roleMappings['']];
  else return roleList.map((d) => roleMappings[d]);
};

const BankItem = () => {
  // Definir nueva instancia de useLocation
  const location = useLocation();

  // Obtener información del context
  const firebase = useContext(FirebaseContext);

  // Obtener id del banco
  const bankId = location.pathname.split('/')[2];

  // Definir state
  const [filterBankOffices, setFilterBankOffices] = useState([]);

  // Obtener información de todos los usuarios asociados al banco
  const [users, loading, error] = useCollection(
    firebase.userLogic.users().where('bankId', '==', bankId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  // Definir effect para setear los valores de filtrado para sucursales
  useEffect(() => {
    if (users) {
      // Obtener nombre de los bancos
      let bankOffices = [];
      users.docs.forEach((doc) => {
        if (
          doc.get('bankOfficeName') &&
          !bankOffices.includes(doc.get('bankOfficeName'))
        ) {
          bankOffices.push(doc.get('bankOfficeName'));
        }
      });

      // Definir arreglo con objetos de filtrado
      const filterBankOffices = bankOffices.map((item) => ({
        text: item,
        value: item,
      }));

      // Setear valores
      setFilterBankOffices(filterBankOffices);
    }
  }, [users]);

  // Definir columnas a mostrar en la tabla
  const columns = [
    {
      title: 'SUCURSAL',
      dataIndex: 'bankOfficeName',
      key: 'bankOfficeName',
      render: (_, record) => record.get('bankOfficeName') || '-',
      sorter: (a, b) => {
        let bankOfficeA = a.get('bankOfficeName') || '-';
        let bankOfficeB = b.get('bankOfficeName') || '-';
        return bankOfficeA.localeCompare(bankOfficeB);
      },
      filters: filterBankOffices,
      onFilter: (value, record) => (record.get('bankOfficeName') || '-') === value,
    },
    {
      title: 'PERSONAL',
      dataIndex: 'user',
      key: 'user',
      render: (_, record) =>
        record.get('firstName') +
        ' ' +
        record.get('lastName') +
        ' - ' +
        record.get('email'),
      sorter: (a, b) => {
        let adminA = a.get('firstName') + ' ' + a.get('lastName');
        let adminB = b.get('firstName') + ' ' + b.get('lastName');
        return adminA.localeCompare(adminB);
      },
    },
    {
      title: 'SUPERVISOR',
      dataIndex: 'boss',
      key: 'boss',
      render: (_, record) => record.get('bankOfficeBoss') || '-',
      sorter: (a, b) => {
        let bossA = a.get('bankOfficeBoss') || '-';
        let bossB = b.get('bankOfficeBoss') || '-';
        return bossA.localeCompare(bossB);
      },
    },
    {
      title: 'SUBORDINADOS',
      dataIndex: 'numExec',
      key: 'numExec',
      render: (_, record) => {
        if (record.get('roles.BANKADMIN') === 'BANKADMIN') {
          const associateExecutives = users.docs.filter(
            (doc) =>
              doc.get('bankOfficeBoss') &&
              doc.get('bankOfficeBoss') === record.get('email')
          ).length;
          return associateExecutives;
        }
        return 0;
      },
      sorter: (a, b) => {
        let associateExecutivesA = [];
        let associateExecutivesB = [];
        if (a.get('roles.BANKADMIN') === 'BANKADMIN') {
          associateExecutivesA = users.docs.filter(
            (doc) =>
              doc.get('bankOfficeBoss') &&
              doc.get('bankOfficeBoss') === a.get('email')
          );
        }
        if (b.get('roles.BANKADMIN') === 'BANKADMIN') {
          associateExecutivesB = users.docs.filter(
            (doc) =>
              doc.get('bankOfficeBoss') &&
              doc.get('bankOfficeBoss') === b.get('email')
          );
        }
        return associateExecutivesA.length - associateExecutivesB.length;
      },
    },
    {
      title: 'ROLES',
      dataIndex: 'roles',
      key: 'roles',
      render: (_, record) => {
        const roleList = getRoles(record);
        return (
          <div>
            {roleList.map((d, i) => (
              <Tag key={i} color={'blue'}>
                {d}
              </Tag>
            ))}
          </div>
        );
      },
      sorter: (a, b) => {
        const roleListA = getRoles(a);
        const roleListB = getRoles(b);
        return roleListA[0].localeCompare(roleListB[0]);
      },
      filters: [
        { text: 'Ejecutivo', value: 'Ejecutivo' },
        { text: 'Administrador', value: 'Administrador' },
      ],
      onFilter: (value, record) => getRoles(record).includes(value),
    },
  ];

  // Renderizar componente de Spin si se está cargando la información
  if (loading) return <Spin className="spinner" />;

  // Renderizar texto de error en caso de que exista
  if (error) return <p>Error</p>;

  // Renederizar componente
  return (
    <div id="bank-item">
      <div className={'bank-head'}>
        <h1>Listado de Sucursales, Administradores y Ejecutivos</h1>
        <div className={'bank-office-head'}>
          <Button type="link" href={ROUTES.BANKS_PAGE}>
            <ArrowLeftOutlined />
            Volver
          </Button>
        </div>
      </div>
      <Divider />
      <div className={'table'}>
        <Table dataSource={users.docs} columns={columns} rowKey={(d) => d.id} />
      </div>
    </div>
  );
};

export default BankItem;
