import React from 'react';
import { Button, Modal, Tag } from 'antd';

const ShowUserModal = ({ openModal, setModal, getRoles }) => {
  const roles = openModal ? getRoles(openModal) : [];

  return (
    <Modal
      onCancel={() => {
        setModal(null);
      }}
      footer={
        <div id="user-modal-footer">
          <Button onClick={() => setModal(null)} type={'primary'}>
            Ok
          </Button>
        </div>
      }
      visible={!!openModal}
    >
      {openModal && (
        <div id="user-modal-body">
          <h3>{`${openModal.get('firstName')} ${openModal.get('lastName')}`}</h3>
          <span id={'email'}>{openModal.get('email')}</span>
          <span id={'roles'}>
            {roles.map((d, i) => (
              <Tag key={i} color={'blue'}>
                {d}
              </Tag>
            ))}
          </span>
          <span id="extra-info">
            {roles.includes('Normal') && (
              <p>
                Numero de solicitudes creadas: {openModal.get('applications').length}
              </p>
            )}
            {roles.includes('Administrador de banco') && (
              <div>
                <p>Admin</p>
                <p>{openModal.get('bankName')}</p>
              </div>
            )}
            {roles.includes('Ejecutivo de banco') && (
              <div>
                <p>
                  Numero de solicitudes asignadas:{' '}
                  {openModal.get('assignedApplications').length}
                </p>
                <p>{openModal.get('bankName')}</p>
              </div>
            )}
          </span>
        </div>
      )}
    </Modal>
  );
};

export default ShowUserModal;
