// Importar librerías
import React, { useState } from 'react';
import { message, Button, Modal } from 'antd';

const DeleteGraphDataModal = ({ openModal, setModal, firebase, currentDataId }) => {
  // Definir state
  const [loading, setLoading] = useState(false);

  /**
   *
   * Elimina la información de un dato de interés.
   */
  const onDelete = async () => {
    // Habilitar spinner de carga
    setLoading(true);

    // Intentar almacenar en la BD
    try {
      await firebase.interestRateLogic.deleteInterestRate(currentDataId);

      // Colocar mensaje de éxito
      message.success('Información eliminada correctamente');

      // Setear valores de los estados Loading y Modal
      setModal(null);
    } catch (error) {
      message.error(error.message);
    }

    // Deshabilitar spinner de carga
    setLoading(false);
  };

  // Renderizar componente
  return (
    <Modal
      onCancel={() => {
        setModal(null);
      }}
      confirmLoading={loading}
      footer={
        <div id="bank-modal-footer">
          <Button onClick={() => onDelete()}>Eliminar</Button>
        </div>
      }
      visible={openModal}
    >
      <div id="bank-modal-body">
        <h3>Eliminar Data de Interés</h3>
        <p>
          Se perderá parte de la información de la gráfica que es mostrada en la
          sección de actualidad de Flit-Web y en caso de el periodo esté incluído
          dentro de los primeros 6 meses. Desea continuar?
        </p>
      </div>
    </Modal>
  );
};

export default DeleteGraphDataModal;
