// Importar librerías
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout } from 'antd';

// Importar rutas
import * as ROUTES from './../../constants/routes';

// Importar context de user
import { AuthUserContext } from './../../context';

// Importar subcomponente Header
const { Header } = Layout;

// Definir títulos para las rutas
const locationNames = {};
locationNames[ROUTES.HOME] = (name) => `¡Hola ${name}!`;
locationNames[ROUTES.BANKS_PAGE] = () => 'Manejo de Instituciones Bancarias';
locationNames[ROUTES.BANK_OFFICES_PAGE] = () => 'Manejo de Sucursales';
locationNames[ROUTES.USERS_PAGE] = () => 'Usuarios de Flit';
locationNames[ROUTES.APPLICATIONS_PAGE] = () => 'Solicitudes';
locationNames[ROUTES.FINANTIAL_EDUCATION] = () => 'Educación Financiera';
locationNames[ROUTES.ARTICLES_PAGE] = () => 'Actualidad Financiera';
locationNames[ROUTES.METRICS_PAGE] = () => 'Métricas';
locationNames[ROUTES.AVERAGE_INTEREST_RATE_PAGE] = () =>
  'Datos sobre Tasas de Interés Promedio';
locationNames[ROUTES.BANKS_PARAMETERIZATION_PAGE] = () =>
  'Parametrización Instituciones';

const CustomHeader = () => {
  // Definir nueva instancia de useLocation
  const location = useLocation();

  // Obtener información del context de user
  const authUser = useContext(AuthUserContext);

  // Renderizar componente
  return (
    <Header id="header">
      <div className="site-name-2">
        <p>
          {locationNames['/' + location.pathname.split('/')[1]]
            ? locationNames['/' + location.pathname.split('/')[1]](
                authUser.firstName
              )
            : ''}
        </p>
      </div>
      <div className="account-info-2">
        <p>{authUser.firstName + ' ' + authUser.lastName}</p>
      </div>
    </Header>
  );
};
export default CustomHeader;
