// Importar librerías
import React, { useState, useContext } from 'react';
import { message, Button, Modal } from 'antd';

// Importar context de firebase
import { FirebaseContext } from '../../context';

const DeleteBankOfficeModal = ({ openModal, setOpenModal, bankOffice }) => {
  // Obtener información del context de firebase
  const firebase = useContext(FirebaseContext);

  // Definir state
  const [loading, setLoading] = useState(false);

  /**
   * Elimina la información de una sucursal de la BD.
   */
  const deleteBankOffice = async () => {
    // Habilitar estado de carga
    setLoading(true);
    try {
      // Definir valores necesarios para la eliminación.
      const values = {
        bankId: bankOffice.get('bankId'),
        bankOfficeId: bankOffice.id,
        bankOfficeUsers: bankOffice
          .get('bankOfficeAdmins')
          .concat(bankOffice.get('bankOfficeExecutives')),
      };

      // Eliminar información
      await firebase.bankOfficeLogic.deleteBankOffice(values);

      // Definir mensaje de éxito
      message.success('Sucursal eliminada correctamente');

      // Cerrar modla de eliminación
      setOpenModal(false);
    } catch (error) {
      message.error(error.message);
    }

    // Terminar estado de carga
    setLoading(false);
  };

  // Renderizar componente
  return (
    <Modal
      onCancel={() => {
        setOpenModal(null);
      }}
      confirmLoading={loading}
      footer={
        <div id="bank-modal-footer">
          <Button onClick={() => deleteBankOffice()}>Eliminar</Button>
        </div>
      }
      visible={openModal}
    >
      <div id="bank-modal-body">
        <h3>Eliminar Información Sucursal</h3>
        <p>
          Esta acción eliminará la Sucursal seleccionada, eliminará a todos sus
          Administradores y dejará disponibles a todos los Ejecutivos en la
          Institución Financiera. Seguro que desea realizar esta acción?
        </p>
      </div>
    </Modal>
  );
};

export default DeleteBankOfficeModal;
