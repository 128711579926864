// Importar librerías
import React, { useState, useContext, useEffect } from 'react';
import { message, Input, Form, Button, Modal, Checkbox } from 'antd';

// Importar context de firebase
import { FirebaseContext } from './../../context';

// Importar constantes
import { appOptionsBankOffice } from '../../constants/appTypes';

const EditBankOfficeModal = ({ openModal, setOpenModal, bankOffice }) => {
  // Obtener información del context
  const firebase = useContext(FirebaseContext);

  // Definir state
  const [loading, setLoading] = useState(false);

  // Crear instancias de useForm
  const [bankOfficeInfoFormInstance] = Form.useForm();

  // Definir effect para setear valores iniciales del formulario
  useEffect(() => {
    if (bankOffice && openModal) {
      bankOfficeInfoFormInstance.setFieldsValue({
        bankOfficeName: bankOffice.get('bankOfficeName'),
        bankOfficeAppTypes: bankOffice.get('bankOfficeAppTypes'),
      });
    }
  }, [bankOffice, bankOfficeInfoFormInstance, openModal]);

  /**
   *
   * @param {*} values
   * Almacena la información de la sucursal que se está editando en la BD.
   */
  const onFinish = async (values) => {
    // Habilitar spinner de carga
    setLoading(true);

    // Agregar información adicional a los valores de entrada
    values = {
      ...values,
      bankOfficeId: bankOffice.id,
      bankId: bankOffice.get('bankId'),
      bankName: bankOffice.get('bankName'),
      bankOfficeOldAppTypes: bankOffice.get('bankOfficeAppTypes'),
    };

    // Almacenar en la Base de Datos
    try {
      await firebase.bankOfficeLogic.updateBankOffice(values);

      // Mostrar mensaje de éxito
      message.success('Información editada exitosamente');

      // Limpiar formulario
      bankOfficeInfoFormInstance.resetFields();

      // Setear valor para el state Modal
      setOpenModal(false);
    } catch (error) {
      message.error(error.message);
    }

    // Deshabilitar carga del spinner
    setLoading(false);
  };

  /**
   *
   * @param {*} errorInfo
   * Muestra un error en caso de que no se haya podido guardar la información en la BD.
   */
  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  // Renderizar componente
  return (
    <Modal
      onCancel={() => {
        setOpenModal(null);
      }}
      confirmLoading={loading}
      footer={<div id="bank-modal-footer"></div>}
      visible={openModal}
    >
      <div id="bank-modal-body">
        <h3>Editar Información Sucursal</h3>
        <Form
          form={bankOfficeInfoFormInstance}
          className={'modal-form'}
          layout={'vertical'}
          name="link_form"
          onFinish={(values) => onFinish(values)}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Nombre de la Sucursal"
            name="bankOfficeName"
            rules={[{ required: true, message: 'Agregue el nombre de la sucursal' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Tipo de Solicitudes"
            name="bankOfficeAppTypes"
            rules={[
              {
                required: true,
                message:
                  'Seleccione al menos un tipo de solicitud que maneje la sucursal',
              },
            ]}
          >
            <Checkbox.Group>
              {appOptionsBankOffice.map((type, index) => (
                <Checkbox key={index} value={type.value}>
                  {type.label}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
          <Form.Item className={'button-holder'}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Actualizar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditBankOfficeModal;
