import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import { Form, Input, Button, Tabs } from 'antd';

import { withFirebase } from './../../utils';

import * as ROUTES from './../../constants/routes';
import { ReactComponent as BigLogo } from './icons/big-logo.svg';

const { TabPane } = Tabs;

const SignInPage = () => (
  <div className="sign-in-div">
    <div className={'flit-logo'}>
      <BigLogo />
    </div>
    <div className={'sign-in-form'}>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Iniciar Sesión" key="1">
          <SignInForm />
        </TabPane>
        <TabPane tab="Crear cuenta" key="2">
          Contactate con un administrador para poder entrar a este sitio
        </TabPane>
      </Tabs>
    </div>
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (values) => {
    const { email, password } = values;

    this.props.firebase.authLogic
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        this.setState({ error });
      });
  };

  render() {
    const { error } = this.state;

    return (
      <Form
        name="normal_login"
        initialValues={{
          remember: true,
        }}
        layout="vertical"
        onFinish={this.onSubmit}
        hideRequiredMark={true}
      >
        <Form.Item
          // label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your Email!',
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          // label="Contraseña"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your Password!',
            },
          ]}
        >
          <Input type="password" placeholder="Password" />
        </Form.Item>
        <div className={'form-actions'}>
          <Form.Item className={'link'}>
            <Link to={ROUTES.PASSWORD_FORGET}>¿Olvidaste tu contraseña?</Link>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className={'button'} block>
              Iniciar Sesión
            </Button>
          </Form.Item>
        </div>
        {error && <p>{error.message}</p>}
      </Form>
    );
  }
}

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

export default SignInPage;

export { SignInForm };
