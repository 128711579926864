// Bank Office API //
class interestRateLogic {
  // Definir constructor de la clase
  constructor(db, fieldValue) {
    this.db = db;
    this.fieldValue = fieldValue;
  }

  // Funciones de la Clase para la Colección interestRate
  /**
   *
   * @param {*} id
   * Devuelve un documento de la colección interestRate.
   */
  getInterestRateById = (id) => this.db.doc(`interestRate/${id}`);

  /**
   * Devuelve todos los documentos de la colección interestRate.
   */
  getInterestRates = () => this.db.collection('interestRate');

  /**
   *
   * @param {*} values
   * Crea un nuevo documento para la colección interestRate.
   */
  createInterestRate = async (values) => {
    try {
      // Crear información en la BD
      await this.getInterestRates().add({
        ...values,
        createdAt: this.fieldValue.serverTimestamp(),
      });

      // Devolver promesa resuelta
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(
        new Error(
          'Hubo un error al intentar crear la información en la base de datos'
        )
      );
    }
  };

  /**
   *
   * @param {*} values
   * Edita la información de un dato de interés.
   */
  updateInterestRate = async (values) => {
    try {
      // Obtener valores de entrada
      const { year, month, week, consumeRate, mortgageRate, id } = values;

      // Actualizar información
      await this.getInterestRateById(id).update({
        year,
        month,
        week,
        consumeRate,
        mortgageRate,
      });

      // Devolver promesa resuelta
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(
        new Error(
          'Hubo un error al intentar actualizar la información de la tasa en la base de datos'
        )
      );
    }
  };

  /**
   *
   * @param {*} id
   * Elimina la información de un dato de interés.
   */
  deleteInterestRate = async (id) => {
    try {
      // Eliminar información en la BD
      await this.getInterestRateById(id).delete();

      // Devolver promesa resuelta
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(
        new Error(
          'Hubo un error al intentar eliminar la información de la tasa en la base de datos'
        )
      );
    }
  };
}

export default interestRateLogic;
