// Importar librerías
import React, { useState, useContext } from 'react';
import { message, Button, Modal } from 'antd';

// Importar context de firebase
import { FirebaseContext } from '../../context';

const DeleteBankModal = ({ openModal, setModal, currentBank }) => {
  // Obtener información del context de firebase
  const firebase = useContext(FirebaseContext);

  // Definir state
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(null);

  /**
   *
   * Elimina la información de un banco.
   */
  const onDelete = async () => {
    // Habilitar spinner de carga
    setLoading(true);

    // Intentar almacenar en la BD
    try {
      await firebase.bankLogic.bankDelete(currentBank.id);

      // Colocar mensaje de éxito
      message.success(
        'Toda la información del Banco ha sido eliminada exitosamente'
      );

      // Setear valores de los estados Loading y Modal
      setConfirmModal(null);
    } catch (error) {
      message.error(error.message);
    }

    // Deshabilitar spinner de carga
    setLoading(false);
  };

  /**
   * Setea los valores para los estados openModal y confirmModal.
   */
  const onConfirm = () => {
    setModal(null);
    setTimeout(() => {
      setConfirmModal(true);
    }, 1000);
  };

  // Renderizar componente
  return (
    <>
      <Modal
        onCancel={() => {
          setModal(null);
        }}
        footer={
          <div id="bank-modal-footer">
            <Button onClick={() => onConfirm()}>Confirmar</Button>
          </div>
        }
        visible={openModal}
      >
        <div id="bank-modal-body">
          <h3>Eliminar Banco</h3>
          <p>
            Se perderán todas las respuestas existentes. El proceso puede tardar
            dependiendo del volumen de información. Desea continuar?
          </p>
        </div>
      </Modal>
      <Modal
        onCancel={() => {
          setConfirmModal(null);
        }}
        confirmLoading={loading}
        footer={
          <div id="bank-modal-footer">
            <Button onClick={() => onDelete()}>Eliminar</Button>
          </div>
        }
        visible={confirmModal}
      >
        <div id="bank-modal-body">
          <h3>Confirmar Eliminación de Banco</h3>
          <p>
            Toda la información relacionada al Banco, a las Sucursales, a los
            Administradores y a los Ejecutivos será borrada de la Base de Datos. Esta
            información no podrá ser recuperada. Realmente está seguro de querer
            realizar esta acción?
          </p>
        </div>
      </Modal>
    </>
  );
};

export default DeleteBankModal;
