// Importar librerías
import React, { useState, useContext } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Button, Table, Divider, Space } from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar context de firebase
import { FirebaseContext } from './../../context';

// Importar otros componentes
import NewBankModal from './NewBankModal';
import NewBankOfficeModal from './NewBankOfficeModal';
import EditBankModal from './EditBankModal';
import DeleteBankModal from './DeleteBankModal';

// Definir formato de fecha
const DATE_FORMAT = 'DD/MM/YYYY';

const BankList = () => {
  // Definir context de firebase
  const firebase = useContext(FirebaseContext);

  // Obtener información de los bancos
  const [banks, loading] = useCollection(firebase.bankLogic.banks(), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  // Defini state
  const [openNewModal, setOpenNewModal] = useState(null);
  const [openNewOfficeModal, setOpenNewOfficeModal] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(null);
  const [currentBank, setCurrentBank] = useState(null);

  /**
   *
   * @param {*} _
   * @param {*} record
   * Rederiza un componente con las acciones a realizar en el listado de sucursales.
   */
  const renderActions = (_, record) => {
    return (
      <Space>
        <Link to={`${ROUTES.BANKS_PAGE}/${record.id}`}>
          <EyeOutlined title="Ver" />
        </Link>
        <EditOutlined title="Editar" onClick={() => setField(record, true)} />
        <DeleteOutlined title="Eliminar" onClick={() => setField(record, false)} />
      </Space>
    );
  };

  // Definir columnas a mostrar en la tabla
  const columns = [
    {
      title: 'NOMBRE',
      dataIndex: 'bankName',
      key: 'bankName',
      render: (_, record) => record.get('bankName'),
      sorter: (a, b) => a.get('bankName').localeCompare(b.get('bankName')),
    },
    {
      title: 'NUMERO DE SUCURSALES',
      dataIndex: 'nOffices',
      key: 'nOffices',
      render: (_, record) =>
        !record.get('bankOffices') ? 0 : record.get('bankOffices').length,
      sorter: (a, b) => {
        if (!a.get('bankOffices') || !b.get('bankOffices')) {
          return -1;
        }
        return a.get('bankOffices').length - b.get('bankOffices').length;
      },
    },
    {
      title: 'NUMERO DE ADMINISTRADORES',
      dataIndex: 'nAdmins',
      key: 'nAdmins',
      render: (_, record) => record.get('bankAdmins').length,
      sorter: (a, b) => a.get('bankAdmins').length - b.get('bankAdmins').length,
    },
    {
      title: 'NUMERO DE EJECUTIVOS',
      dataIndex: 'nExecutives',
      key: 'nExecutives',
      render: (_, record) => record.get('bankExecutives').length,
      sorter: (a, b) =>
        a.get('bankExecutives').length - b.get('bankExecutives').length,
    },
    {
      title: 'FECHA DE CREACIÓN',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => {
        if (!record.get('createdAt')) {
          return <p></p>;
        }
        return moment(record.get('createdAt').toDate()).format(DATE_FORMAT);
      },
      sorter: (a, b) => {
        if (!a.get('createdAt') || !b.get('createdAt')) {
          return -1;
        }
        return a.get('createdAt').toDate() - b.get('createdAt').toDate();
      },
    },
    {
      title: 'ACCIONES',
      dataIndex: 'actions',
      key: 'actions',
      render: renderActions,
    },
  ];

  /**
   *
   * @param {*} record
   * Actualiza la información de los estados seletectBank y openDeleteModal,
   */
  const setField = (record, bool) => {
    // Setear los valores
    setCurrentBank(record);
    bool ? setOpenEditModal(true) : setOpenDeleteModal(true);
  };

  // Renderizar componente
  return (
    <div className={'banks-list'}>
      <div id={'banks-head'}>
        <h1>Instituciones Bancarias</h1>
        <div>
          <Button
            type={'primary'}
            icon={<PlusOutlined />}
            onClick={() => setOpenNewModal(true)}
          >
            Nuevo Banco
          </Button>
          <Button
            type={'primary'}
            icon={<PlusOutlined />}
            onClick={() => setOpenNewOfficeModal(true)}
            className="last-botton"
          >
            Nueva Sucursal
          </Button>
        </div>
      </div>
      <Divider />
      <div id={'bank-main'}>
        <NewBankModal openModal={openNewModal} setModal={setOpenNewModal} />
        <NewBankOfficeModal
          openModal={openNewOfficeModal}
          setModal={setOpenNewOfficeModal}
          banks={banks}
        />
        <EditBankModal
          openModal={openEditModal}
          setModal={setOpenEditModal}
          currentBank={currentBank}
        />
        <DeleteBankModal
          openModal={openDeleteModal}
          setModal={setOpenDeleteModal}
          currentBank={currentBank}
        />
        <Table
          loading={loading}
          columns={columns}
          dataSource={banks ? banks.docs : []}
          rowKey={(r) => r.id}
        />
      </div>
    </div>
  );
};

export default BankList;
